import React, { useContext, useEffect, useState } from 'react';

import { get } from '../lib/api';
import { getTokenAndUid } from '../lib/utils';
import { deleteChapterUIDs } from '../pages/Home/constants';
import {
  filterNotificationsToBeDisplayed,
  generateNotificationsFromBooks,
} from '../pages/Home/helpers';
import { AccountContext } from './accountProvider';

const NotificationsContext = React.createContext({
  notificationArr: [],
  count: 0,
  onUpdateNotification: () => {},
  onFetchNotification: () => {},
  onResetNotification: () => {},
});

const NotificationsProvider = ({ children }) => {
  const { isHindiUgcUser, isUgcUser } = useContext(AccountContext);
  const [notificationArr, setNotificationArr] = useState([]);
  const [chapterNotificationArr, setChapterNotificationArr] = useState([]);
  const [count, setCount] = useState(0);

  const onUpdateNotification = (notificationArray) => {
    if (notificationArray.length > 0) {
      setNotificationArr((prevArr) => [...prevArr, ...notificationArray]);
      setCount((prevCount) => prevCount + notificationArray.length);
    }
  };

  const onResetNotification = () => {
    setNotificationArr([]);
    setCount(0);
  };

  const onUpdateNotificationFromAPI = (arr) => {
    if (arr.length > 0) {
      setChapterNotificationArr((prevArr) => [...prevArr, ...arr]);
      setCount((prevCount) => prevCount + arr.length);
    }
  };

  const onFetchNotification = () => {
    onResetNotification();
    const { uid } = getTokenAndUid();
    const isPublicPage =
      window.location.href.includes('author-welfare') ||
      window.location.href.includes('.html');
    if (uid && !isPublicPage) {
      if (deleteChapterUIDs?.includes(uid)) {
        get(
          `/approval_requests/list_by_uid?is_novel=1&request_type=chapter_delete`
        ).then((res) => {
          onUpdateNotificationFromAPI(
            res?.data?.filter(
              (chp) => chp?.status === 'APPROVED' || chp?.status === 'REJECTED'
            )
          );
        });
      }
      if (!(isHindiUgcUser || isUgcUser)) {
        get('/content_api/book.creator_ben_prog/?is_novel=1')
          .then((response) => {
            const writerNotificationArr = generateNotificationsFromBooks(
              response.books
            );
            const filteredWriterNotificationArr =
              filterNotificationsToBeDisplayed(writerNotificationArr);
            onUpdateNotification(filteredWriterNotificationArr);
          })
          .catch((error) => {
            console.error('Error in creator program details', error);
          });
        get(`/content_api/book.user_application/${uid}/?is_novel=1`)
          .then((response) => {
            onUpdateNotification(
              response.result.filter(({ is_viewed }) => !is_viewed)
            );
          })
          .catch((error) => {
            console.error('Error in user application details', error);
          });
      } else {
        onUpdateNotification([]);
      }
    }
  };

  useEffect(() => {
    onFetchNotification();
  }, []);

  const notificationsContext = {
    notificationArr,
    chapterNotificationArr,
    count,
    onUpdateNotification,
    onFetchNotification,
    onResetNotification,
  };
  return (
    <NotificationsContext.Provider value={notificationsContext}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext };
export default NotificationsProvider;
