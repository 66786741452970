import React from 'react'

const Pen = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M13.394 6.418a1.092 1.092 0 0 0-.26-1.5L8.977 1.95a1.046 1.046 0 0 0-1.473.265l-.802 1.166 5.89 4.203.802-1.167zM7.116 10.798a.952.952 0 0 1-1.342.242.993.993 0 0 1-.237-1.368.952.952 0 0 1 1.342-.24c.436.31.542.922.237 1.366z" fill="currentColor"/>
    <path d="m10.755 7.153-3.099-2.21-2.158.832a3.215 3.215 0 0 0-2.024 2.62l-.807 6.559 2.465-3.531a1.724 1.724 0 0 1-.09-2.27 1.647 1.647 0 0 1 2.206-.334c.711.48.952 1.43.56 2.2a1.655 1.655 0 0 1-2.088.8l-2.453 3.514L9 12.338a3.232 3.232 0 0 0 1.725-2.834l.03-2.351z" fill="currentColor"/>
</svg>
)

export default Pen
