import { get, post } from 'lib/api';

import { dummy_entity_id_for_all_book } from '../pages/Income/Author/constants';

export const getCreatorDetails = async () => {
  const response = await get(`/user_api/user.get_creator_details`, {
    params: {
      is_novel: 1,
    },
  });
  return response;
};

export const addComment = async (data) => {
  const response = await post('/social/academy_comment.create?is_novel=1', {
    data,
  });
  return response;
};

export const getComments = async (lessonId, pageNumber) => {
  const response = await get(
    '/social/comment.get_academy_comments?is_novel=1',
    {
      params: {
        lesson_id: lessonId.toString(),
        page_no: pageNumber,
      },
    }
  );
  return response;
};

export const getNotificationCount = async () => {
  const response = await get('/notification/count');
  return response;
};

export const deleteRequest = async (data) => {
  const response = await post(
    '/approval_requests/create_approval_request?is_novel=1',
    {
      data,
    }
  );
  return response;
};

export const getBalanceHistory = async (
  bookSelected,
  payoutTabSelected,
  walletDetails
) => {
  const baseUrl = '/content_api/book.get_withdrawals';
  const params = `time_period=${payoutTabSelected}&wallet_id=${walletDetails?.wallet_id}`;

  const api =
    bookSelected === dummy_entity_id_for_all_book
      ? `${baseUrl}?${params}`
      : `${baseUrl}?book_id=${bookSelected}&${params}`;

  const response = await get(api, { params: { is_novel: 1 } });
  return response;
};

export const getVoiceList = async (genre, bookLanguage) => {
  const api = genre
    ? `/content_api/ugc/audio/voice_library/?vendor=eleven_labs&genre=${genre}&is_novel=1&language=${bookLanguage}`
    : `/content_api/ugc/audio/voice_library/?vendor=eleven_labs&is_novel=1&language=${bookLanguage}`;
  const response = await get(api);
  return response;
};

export const getGenreList = async (isBackgroundMusic) => {
  const api = isBackgroundMusic
    ? `/content_api/ugc/audio/supported_genres/?is_background=1`
    : '/content_api/ugc/audio/supported_genres/';
  const response = await get(api);
  return response;
};

export const updateUserForNotifications = async (data) => {
  await post('/user_api/user.update?is_novel=1', { data });
};

// profile
export const getUserAddress = async (uid) => {
  const response = await get(`/user_api/user_addresses/${uid}/?is_novel=1`);
  return response;
};

export const getUserBankAccounts = async (uid) => {
  const response = await get(`/user_api/user_bank_accounts/${uid}/?is_novel=1`);
  return response;
};

export const getRowCreator = async (uid) => {
  const response = await get(`/user_api/row_creator/${uid}/?is_novel=1`);
  return response;
};

export const userUpdate = async (data) => {
  const response = await post('/user_api/user.update?is_novel=1', {
    data,
  });
  return response;
};

export const createCreatorAccount = async (data) => {
  const response = await post(
    '/user_api/user.create_creator_account?is_novel=1',
    {
      data,
    }
  );
  return response;
};

export const userAddresses = async (data) => {
  const response = await post('/user_api/user_addresses/?is_novel=1', {
    data,
  });
  return response;
};

export const rowCreator = async (data) => {
  const response = await post('/user_api/row_creator/?is_novel=1', {
    data,
  });
  return response;
};

export const userBankAccounts = async (data) => {
  const response = await post('/user_api/user_bank_accounts/?is_novel=1', {
    data,
  });
  return response;
};
