import { checkOverseasUser } from '../../lib/utils';
import { homePageContentAccordingToLocale } from './constants';

export const generateNotificationObject = (condition, book) => {
  const obj = {
    id: book?.id || book?.book_id,
    bookId: book?.id || book?.book_id,
    seen: false,
    isWriterNotification: true,
    bookCoverUrl: book.image_url,
    type: condition,
    create_time: book.create_time,
  };
  if (condition === 'Feature') {
    obj.heading = 'Congratulations! Your novel is featured';
    obj.moreInfo =
      'For more views on the novel, publish chapters everyday & retain readers.';
    obj.actionBtnText = 'Write Here';
    obj.statusColor = '--lime-300';
    obj.btnTextColor = '--lime-800';
    obj.btnBgColor = '--lime-op-16';
    obj.btnLink = `/shows/novels/id/${obj.bookId}?tab=draft`;
  } else if (condition === 'Live') {
    obj.heading = 'Congratulations! Your novel is live';
    obj.moreInfo =
      'Write at least, 3 chapters to get it featured in home feed of the Pocket Novels app.';
    obj.actionBtnText = 'Live Here';
    obj.statusColor = '--corn-300';
    obj.btnTextColor = '--corn-800';
    obj.btnBgColor = '--corn-op-16';
    obj.btnLink = `https://www.pocketnovel.com/novel/${obj.bookId}`;
  } else if (condition === 'Unpublished') {
    obj.heading = 'Hey! What you’re waiting for';
    obj.moreInfo =
      'Publish at least, 1 chapter to get it live on Pocket Novels app.';
    obj.actionBtnText = 'Publish Here';
    obj.statusColor = '--azure-300';
    obj.btnLink = `/shows/novels/id/${obj.bookId}?tab=draft`;
    obj.btnTextColor = '--azure-800';
    obj.btnBgColor = '--azure-op-16';
  }
  return obj;
};

export const generateNotificationsFromBooks = (booksArray) => {
  const loggedInUID = localStorage.getItem('uid');
  if (!loggedInUID) return [];
  const writerNotifications =
    JSON.parse(localStorage.getItem('writer_notifications')) || {};
  const writerObject = writerNotifications[loggedInUID] || {};
  if (!writerObject.timestamp) writerObject.timestamp = Date.now();
  const localArray = writerObject.data || [];

  const newLocalArray = booksArray.map((book) => {
    const isBookPublished = book.status === 'approved';
    let notificationType = null;
    if (isBookPublished && book.word_count > 5000) notificationType = 'Feature';
    if (isBookPublished && book.word_count < 5000) notificationType = 'Live';
    if (!isBookPublished && book.word_count < 1000)
      notificationType = 'Unpublished';
    const notificationInLocal =
      localArray.find((notification) => notification?.id === book.book_id) ||
      {};
    if (notificationInLocal) {
      if (notificationInLocal.type === notificationType)
        return notificationInLocal;
      return generateNotificationObject(notificationType, book);
    }
    return generateNotificationObject(notificationType, book);
  });
  const newWriterNotifications = {
    ...writerNotifications,
    [loggedInUID]: { ...writerObject, data: newLocalArray },
  };
  // localStorage.setItem('writer_notifications', JSON.stringify(newWriterNotifications))
  return newLocalArray;
};

export const filterNotificationsToBeDisplayed = (notifications) => {
  const loggedInUID = localStorage.getItem('uid');
  if (!loggedInUID) return [];
  const writerNotifications =
    JSON.parse(localStorage.getItem('writer_notifications')) || {};
  const writerObject = writerNotifications[loggedInUID] || {};
  const timestamp = writerObject.timestamp || Date.now();

  return notifications.filter((notification) => {
    const condition1 = !notification.seen;
    let condition2 = false;
    const condition3 = notification.type;
    if (notification.type === 'Unpublished')
      condition2 = new Date(notification.create_time) > timestamp;
    // Reasoning: we don't want to show all the notifications for all books ever created in the past. The list can be large
    if (notification.type === 'Live')
      condition2 = new Date(notification.create_time) > timestamp;
    // Reasoning: same as above
    if (notification.type === 'Feature') condition2 = true;
    // Reasoning: if we filter by criteria defined in the above case, we will miss a lot of books which were created
    // after this feature is implemented(when this code runs for the first time,or if a user logs in a different machine
    // for the first time. Since in that case also, localstorage will be empty.) but the user later crosses 5000 words
    // (criteria for feature). The downside to showing featured books notifications in all cases is: we may show
    // notifications for all featured books again, even if the user has already seen it(user logged into a different machine)
    // But that's okay since users usually dont have multiple featured books. To further improve this later, we may put a
    // condition like; if timestamp - book_create_time < 3 months, then show feature notification
    return condition1 && condition2 && condition3;
  });
};

export const hideNotification = (notificationId) => {
  const loggedInUID = localStorage.getItem('uid');
  if (!loggedInUID) return;
  const writerNotifications =
    JSON.parse(localStorage.getItem('writer_notifications')) || {};
  const writerObject = writerNotifications[loggedInUID] || {};
  const localArray = writerObject.data || [];

  const notificationIndex = localArray.findIndex(
    (notification) => notification.id === notificationId
  );
  if (notificationIndex < 0) return;
  localArray[notificationIndex].seen = true;
  const newWriterNotifications = {
    ...writerNotifications,
    [loggedInUID]: { ...writerObject, data: localArray },
  };
  // localStorage.setItem('writer_notifications', JSON.stringify(newWriterNotifications))
};

export const isMobileOrTablet = () =>
  /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);

export const getModuleOrTextAccordingToLocale = (
  user,
  key,
  showUSContent,
  isSeries
) => {
  if (isSeries) {
    return homePageContentAccordingToLocale.INUGC[key];
  } else if (showUSContent) {
    return homePageContentAccordingToLocale.US[key];
  } else if (!checkOverseasUser(user)) {
    return homePageContentAccordingToLocale.IN[key];
  } else if (user?.location === 'US') {
    return homePageContentAccordingToLocale['US'][key];
  }
  return homePageContentAccordingToLocale['ROW'][key];
};
