import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ErrorBoundaryWrapper from 'components/ErrorBoundary';
import { FallbackErrorUI } from 'components/ErrorBoundary/fallbackUI';
import Loader from 'components/Loader';
import { theme } from 'designSystem';

import { isLoggedIn } from './lib/utils';

const Welcome = React.lazy(() => import('./components/Welcome'));
const GlobalLogin = React.lazy(() => import('./components/GlobalLogin'));
const Home = React.lazy(() => import('./pages/Home'));
const WritersBenefit = React.lazy(() => import('./pages/WritersBenefit'));
const Income = React.lazy(() => import('./pages/Income'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const Novels = React.lazy(() => import('./pages/Shows/Novels'));
const Profile = React.lazy(() => import('./components/Profile'));
const NovelActionPage = React.lazy(() => import('./pages/Shows/Novels/action'));
const ComingSoon = React.lazy(() => import('./pages/ComingSoon'));
const Logout = React.lazy(() => import('./components/Logout'));
const WritingTips = React.lazy(
  () => import('./pages/Shows/Novels/writingTips')
);
const NotFound404 = React.lazy(() => import('./pages/NotFound404'));
const Resetpassword = React.lazy(
  () => import('./components/GlobalLogin/resetPassword')
);
const Contest = React.lazy(() => import('./pages/Contest'));
const Revenue = React.lazy(() => import('./pages/Revenue'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const Faq = React.lazy(() => import('./pages/Faq'));
const EmailRedirect = React.lazy(() => import('./pages/EmailRedirect'));
const WritersAcademy = React.lazy(() => import('./pages/WritersAcademy'));
const ContentAndCommunityGuidelines = React.lazy(
  () => import('./pages/ContentGuidelines')
);
const Onboarding = React.lazy(() => import('./components/Onboarding'));
const User = React.lazy(() => import('./components/User'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function PrivateOutlet() {
  const location = useLocation();
  return isLoggedIn() ? (
    <Outlet />
  ) : (
    <Navigate to="/logout" state={{ from: location }} />
  );
}

function RouteLoader() {
  return (
    <div className="center full-view-dimensions">
      <Loader
        theme={theme.light}
        size="lg"
        invertColor={false}
        fjordColor={false}
        noPadding={false}
        inline={false}
        color={false}
      />
    </div>
  );
}

const GlobalRoutes = () => {
  return (
    <ErrorBoundaryWrapper FallbackComponent={FallbackErrorUI}>
      <Suspense fallback={<RouteLoader />}>
        <SentryRoutes>
          <Route path="/" element={<User />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Welcome />} />
          <Route path="/email-redirect" element={<EmailRedirect />} />
          <Route path="/contest/:titleId" element={<Contest />} />
          <Route path="/globalLogin" element={<GlobalLogin />} />
          <Route path="/author-welfare" element={<WritersBenefit />} />
          <Route path="/writers-academy/" element={<WritersAcademy />} />
          <Route path="/writers-academy/:id" element={<WritersAcademy />} />
          <Route
            path="/author-welfare/:language"
            element={<WritersBenefit />}
          />
          <Route
            path="/content-and-community-guidelines"
            element={<ContentAndCommunityGuidelines />}
          />
          <Route
            path="/author-welfare-audioseries"
            element={<WritersBenefit />}
          />
          <Route
            path="/author-welfare-audioseries/:language"
            element={<WritersBenefit />}
          />
          <Route path="/author-welfare-usa" element={<WritersBenefit />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route element={<PrivateOutlet />}>
            <Route path="/shows/novels" element={<Novels />} />
            <Route path="/my-creations" element={<Novels />} />
            <Route
              path="/shows/novels/writing-tips"
              element={<WritingTips />}
            />
            <Route
              path="/shows/novels/:action?/:id?"
              element={<NovelActionPage />}
            />
            <Route path="/shows/podcasts" element={<ComingSoon />} />
            <Route path="/analytics/engagement" element={<ComingSoon />} />
            <Route path="/analytics/audience" element={<ComingSoon />} />
            <Route path="/edit-profile" element={<Profile />} />
            <Route path="/home" element={<Home />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/writers-benefit" element={<WritersBenefit />} />
            <Route path="/income" element={<Income />} />
            <Route path="/revenue" element={<Revenue />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/notifications" element={<Notifications />} />
          </Route>
          <Route path="*" element={<NotFound404 />} />
        </SentryRoutes>
      </Suspense>
    </ErrorBoundaryWrapper>
  );
};

export default GlobalRoutes;
