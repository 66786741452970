import styled from 'styled-components';
import {
  compose,
  space,
  layout,
  flexbox,
  border,
  position,
  color,
  variant
} from 'styled-system';

const boxVariants = variant({
  variants: {
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    flexCard: {
      bg: 'surface.foreground',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    normal: {
    },
    hr: {
      height: '1px',
      bg: 'text.textMuted8'
    }
  },
});

export const Box = styled.div(
  boxVariants,
  compose(space, color, layout, flexbox, border, position)
);

Box.defaultProps = {
  variant: 'normal',
};
