import { css } from 'styled-components';

export const FlexStyles = css`
  .inline-flex {
    display: inline-flex;
  }

  .d-none {
    display: none;
  }

  .d-flex,
  .v-d-flex {
    display: flex;
  }

  .center,
  .v-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spread,
  .v-spread {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .space-between,
  .v-space-between {
    display: flex;
    justify-content: space-between;
  }

  .space-between-start,
  .v-space-between-start {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
  }

  .end-align-center,
  .v-end-align-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .align-center,
  .v-align-center {
    display: flex;
    align-items: center;
  }

  .space-evenly,
  .v-space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .align-baseline,
  .v-align-baseline {
    display: flex;
    align-items: baseline;
  }

  .align-stretch,
  .v-align-stretch {
    display: flex;
    align-items: stretch;
  }

  .justify-center,
  .v-justify-center {
    display: flex;
    justify-content: center;
  }

  .justify-center-baseline,
  .v-justify-center-baseline {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .justify-start,
  .v-justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .flex-start,
  .v-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .space-around,
  .v-space-around {
    display: flex;
    justify-content: space-around;
  }

  .space-around-center,
  .v-space-around-center {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .center-flex-end,
  .v-center-flex-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .center-flex-start,
  .v-center-flex-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .space-between-end,
  .v-space-between-end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .flex-start-center,
  .v-flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flex-justify-end,
  .v-flex-justify-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .flex-end,
  .v-flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .flex-end-center,
  .v-flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .align-flex-end,
  .v-align-flex-end {
    display: flex;
    align-items: flex-end;
  }

  .flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .align-content-start,
  .v-align-content-start {
    display: flex;
    align-content: flex-start;
  }

  .v-end-align {
    display: flex;
    justify-content: flex-end;
  }

  .v-d-flex,
  .v-center,
  .v-spread,
  .v-space-between,
  .v-space-between-start,
  .v-end-align-center,
  .v-align-center,
  .v-align-baseline,
  .v-align-stretch,
  .v-justify-center,
  .v-justify-center-baseline,
  .v-justify-start,
  .v-flex-start,
  .v-space-around,
  .v-space-around-center,
  .v-center-flex-end,
  .v-center-flex-start,
  .v-space-between-end,
  .v-flex-start-center,
  .v-flex-justify-end,
  .v-flex-end,
  .v-flex-end-center,
  .v-align-flex-end,
  .v-align-content-start,
  .v-end-align {
    flex-direction: column;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .full-flex {
    flex: 1;
  }

  .full-flex-grow {
    flex-grow: 1;
  }

  .align-item-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
