import { v4 } from 'uuid';

import { makePlatformStringFromDeviceInfo } from '.';
import { post } from '../api';

const currentVersionTag = import.meta.env.VITE_APP_VERSION_TAG || '';

const getDeviceDetails = () => {
  if (!window.UAParser) {
    return {};
  }
  const parser = new window.UAParser();
  const {
    browser: browserObject,
    os: osObject,
    device: deviceObject,
  } = parser.getResult();
  let deviceDetailObj = {
    os_version: osObject?.version || 'Null',
    device_model: deviceObject?.model || 'Null',
    os: osObject?.os || 'Null',
    browser: browserObject?.browser || 'Null',
  };
  return deviceDetailObj;
};

const getLocationInfo = () => {
  return new Promise((resolve, reject) => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;

          fetch(apiUrl)
            .then((res) => res.json())
            .then((data) => {
              fetch(`https://api.ipify.org/?format=json`)
                .then((res) => res.json())
                .then((ipData) => {
                  const locationData = {
                    city: data?.address?.city || 'Null',
                    state: data?.address?.state || 'Null',
                    ip: ipData?.ip || 'Null',
                  };

                  localStorage.setItem(
                    'locationData',
                    JSON.stringify(locationData)
                  );
                  resolve();
                })
                .catch((ipError) => {
                  console.error('Error fetching IP information:', ipError);
                  // reject(ipError);
                });
            })
            .catch((error) => {
              console.error('Error fetching geolocation information:', error);
              // reject(error);
            });
        },
        (error) => {
          localStorage.setItem(
            'locationData',
            JSON.stringify({ ip: 'Null', city: 'Null', state: 'Null' })
          );
          console.error('Error getting geolocation:', error);
          // reject(error);
        }
      );
    } else {
      console.error('Geolocation not supported.');
      // reject('Geolocation not supported.');
    }
  });
};

const getEventDataObj = async (user, bookDetails) => {
  let eventObj = {
    user_email: user?.email || 'Null',
    gender: user?.gender || 'Null',
    mobile_number: user?.phone_number || 'Null',
    session_id: new Date().getTime(),
  };

  if (Object.keys(bookDetails || {}).length > 0) {
    const { create_time, book_id, book_title } = bookDetails;
    eventObj = {
      ...eventObj,
      create_time,
      book_id,
      book_title,
    };
  }
  return eventObj;
};

const getPropsObj = () => {
  let propsObj = {
    referral_medium: sessionStorage.getItem('medium') || 'Null',
    referrer_uid: 'Null',
    environment: 'production',
    app_build_number: 'Null',
    tab: window?.location,
    ...getDeviceDetails(),
  };
  return propsObj;
};

const getAppPlatformString = (source) => {
  let appPlatform = 'writers';
  if (source.includes('pfm')) {
    appPlatform = `writers_${source}`;
  } else if (source.includes('android') || source.includes('ios')) {
    appPlatform = `writers_pn_${source}`;
  } else if (window.screen.width <= 600) {
    appPlatform = `writers_mobile`;
  } else if (window.screen.width <= 992) {
    appPlatform = 'writers_tablet';
  } else {
    appPlatform = 'writers_desktop';
  }
  return appPlatform;
};

const handleNewEventLog = async (
  screenName,
  event,
  metaDataObj = {},
  user,
  bookDetails
) => {
  let uid = localStorage.getItem('uid');
  const metaData = { ...metaDataObj };
  if (metaData.uid) {
    uid = metaData.uid;
  }
  const id = v4();
  const eventId = id.split('-').join('');
  let referrer =
    localStorage.getItem('referrer') !== 'undefined'
      ? localStorage.getItem('referrer')
      : '';
  const medium = sessionStorage.getItem('medium');
  const campaign = sessionStorage.getItem('campaign');
  const source = `${referrer}_medium_${medium || ''}_campaign_${campaign || ''}`;
  let data = {
    screen_name: screenName,
    client_ts: String(Date.now()),
    event,
    view_type: metaData.route,
    source: `${source}_${makePlatformStringFromDeviceInfo()}`,
    resolution: `${window.innerWidth}*${window.innerHeight}`,
    app_version_code:
      localStorage.getItem('native_app_version') || currentVersionTag || 'Null',
    type: metaDataObj.type,
    referrer_code: referrer,
    user_uid: uid,
    app_platform: getAppPlatformString(referrer) || 'writers',
  };
  try {
    const eventDataObj = await getEventDataObj(user, bookDetails);
    data = {
      ...data,
      ...eventDataObj,
      props: JSON.stringify(getPropsObj()),
    };
  } catch (err) {
    console.error('Error getting event data:', err);
  }
  if (!data.type) delete data.type;
  if (!localStorage.getItem('uuid')) {
    const uniqueId = v4().split('-').join('');
    localStorage.setItem('uuid', uniqueId);
  }
  const common_fields = {
    device_id: localStorage.getItem('uuid'),
    uid,
  };
  if (metaData.button) {
    data.module_name = metaData.button;
  }
  if (metaData.button) {
    data.cta_text = metaData.button;
  }
  if (metaData.module_name) {
    data.module_name = metaData.module_name;
  }
  if (metaData.notification_count) {
    data.notification_count = metaData.notification_count;
  }
  if (metaData.author_tier) {
    data.author_tier = metaData.author_tier;
  }
  if (metaData.timestamp) {
    data.timestamp = metaData.timestamp;
  }
  if (metaData.chapter_number) {
    data.chapter_number = metaData.chapter_number;
  }
  if (metaData.time) {
    data.time_spent = String(metaData.time);
  }
  if (metaData.entity_type) {
    data.entity_type = metaData.entity_type;
    data.entity_id = metaData.entity_id;
  }
  if (metaData.timestamp) {
    data.timestamp = metaData.timestamp;
  }
  if (metaData.time_since_load_start) {
    data.time_since_load_start = metaData.time_since_load_start;
  }
  if (metaData.show_id) {
    data.show_id = metaData.show_id;
  }
  if (metaData.word_count) {
    data.word_count = metaData.word_count;
  }
  if (metaData.session_id) {
    data.session_id = metaData.session_id;
  }

  const events = [{ data, eventId }];
  const postData = {
    events,
    common_fields,
    group: 'user_events',
  };

  const platform = 'writers';

  if (import.meta.env.VITE_APP_DEPLOY_ENV !== 'production') {
    console.log('new Event Data 102', event, { eventData: postData });
    return;
  }

  post('https://api.pocketfm.in/v2/logging_data/log', {
    data: postData,
    headers: { platform },
  })
    .then(() => {})
    .catch((err) => {
      console.log('logging  error', err);
    });
};

export { handleNewEventLog, getEventDataObj, getLocationInfo };
