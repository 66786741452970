import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ErrorPage from '../ErrorPage';

const currentVersionTag = import.meta.env.VITE_APP_VERSION_TAG || 'unknown';
const isProd = import.meta.env.VITE_APP_DEPLOY_ENV === 'production';

const uid = window.localStorage.getItem('uid') || '';
const name = window.localStorage.getItem('name') || '';
Sentry.setUser({ id: uid, username: name });

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_KEY,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  release: currentVersionTag,
  environment: isProd ? 'production' : 'staging',
  autoSessionTracking: false,
});

const { ErrorBoundary } = Sentry;

const ErrorBoundaryWrapper = ({ children, FallbackComponent = ErrorPage }) => {
  return <ErrorBoundary fallback={FallbackComponent}>{children}</ErrorBoundary>;
};

export const notifySentry = (error, info) => {
  if (info) {
    Sentry.withScope((scope) => {
      scope.setExtra('info', info);
      Sentry.captureException(new Error(error));
    });
    return;
  }
  Sentry.captureException(new Error(error));
};

export default ErrorBoundaryWrapper;
