import { createGlobalStyle } from 'styled-components';

import { CommonStyles } from 'styles/common';
import { FlexStyles } from 'styles/flex';
import { LayoutStyles } from 'styles/layout';
import { ScrollbarStlyes } from 'styles/scrollbar';

const GlobalStylesCSS = `
  ${LayoutStyles}
  ${FlexStyles}
  ${ScrollbarStlyes}
  ${CommonStyles}
`;

export const GlobalStyles = createGlobalStyle`
    ${GlobalStylesCSS}
`;
