import React from 'react';
const applicationUpdate = {
  application_rejected: [
    'Dear {{name}},',
    'We are sorry to say that your application for your novel has been rejected, you can try applying for Writer’s Benefit Program with some other novel.',
  ],
  application_updated: [
    'Dear {{name}},',
    'At this moment, we cannot sign your novel as it doesn’t meet our minimum performance guidelines. However you can submit your application again after writing next 50,000 words. Please read the editorial feedback to imrpove your chances for selection.',
  ],
  monthly_incomplete: [
    'Dear {{name}},',
    'We can not approve your monthly reward for this month, becuase you haven’t completed the 50K words monthly target.',
    'if you think this happened by mistake you can contact us on our email at support@pocketfm.in',
  ],
  monthly_rejected: [
    'Dear {{name}},',
    'We can not approve your monthly reward for this month, if you think this happened by mistake you can contact us on our email at support@pocketfm.in',
  ],
  milestone_upsell:
    'You are still eligible for Milestone Rewards and will get a definite chance to submit your entry for Pocket VIP Program, after completing 250,000 words.',
  application_accepted: [
    'Your Novel "{{book_title}}" has been accepted under the Writer’s Benefit Program.',
    'You have earned a reward of Rs. {{amount}}.',
  ],
  application_approved: [
    'You have completed the signing process and Rs. {{amount}} has been added to your wallet',
  ],
  monthly_accepted:
    'Your have completed the Monthly Goal of 50K Words for the month of {{month}}, and that makes you eligible for Rs. {{amount}} Award.',
  monthly_approved: [
    'You have completed the Monthly Goal of {{month}} and Rs. {{amount}} has been added to your wallet',
  ],
  milestone_approved: [
    'You have completed 150K Words and as a of result you have now earned {{amount}} award. The amount has been added to your wallet.',
  ],
  wallet_accepted: [
    'Your Withdrawal request has been received and being processed',
  ],
  wallet_approved: ['Your Withdrawn request has been processed'],
  wallet_accepted_notes: [
    'Once your withdrawl request is processed, our team will initiate the transfer to your bank account.',
    'Once the withdrawl is initated from our team, it can be seen in your bank account in 7-8 days',
  ],
  wallet_approved_notes: [
    'Bank generally takes 7-8 days to display the amount in your bank account.',
    'If it has been over 10 days and you still haven’t recieved the amount in the bank account, please contact us at support@pocketfm.in',
  ],
  application_accepted_monthly_reward_upsell:
    'Monthly Rewards of Rs. 5000-8000 on submitting at least 50,000 words monthly and uploading new episodes daily',
  application_accepted_milestone_reward_upsell:
    'Additional Milestone Reward of Rs. 2500  on completing 150,000 words of your Novel',
  application_accepted_notes: [
    'You will receive an email and verbal communication from our team shortly to sign the contract and the process to claim your reward money',
    'Your first monthly cycle will start from the date of signing the contract and complete at the end of the next month. All the episodes you have written beyond first 50,000 words shall be considered for rewards on a pro rata basis, in your first successful monthly cycle completion.',
  ],
  application_accepted_golden_tag:
    'Please wait while our team contact you shortly to begin the process.',
  monthly_accepted_golden_tag:
    'Our Team is reviewing your Novel and will update the status shortly',
  monthly_accepted_notes: [
    'Our team is reviewing your novel and once it is approved, you claim button will be enabled.',
    'You will recieve a confirmation email as well when your claim is approved.',
  ],
};

const novelNotices = {
  applied: {
    title: 'Your Application is submitted',
    message:
      'Congratulations on successful submisson of your novel under the Writer’s Benefit Program. Your application is under review. Meanwhile keep adding more chapters for your readers',
  },
  rejected: {
    title: 'Your Application is Rejected',
    message:
      'At this moment, we cannot sign your novel as it doesn’t meet our minimum performance guidelines. You can start a new novel, and again apply for the Writer’s Benefit Program.',
  },
  approved: {
    title: 'Your Application is Approved!',
    message:
      'Congratulations, your application has been approved. Rs. 5000 has been successfully added to your wallet. Click the button to unlock monthly and milestones rewards. ',
  },
  updated: {
    title: 'Your Application is Updated',
    message:
      'At this moment, we cannot sign your novel as it doesn’t meet our minimum performance guidelines. However you can submit your application again after writing next 50,000 words. Please read the editorial feedback to improve your chances for selection.',
  },
};

const rewardNotices = {
  pending: {
    title: 'Your Monthly Reward for {{month}} is under review',
    message:
      'You claim request for the monthly target of {{month}} is being reviewed, you can track the status of the application from the button below',
  },
  approved: {
    title: 'Your Monthly Reward for {{month}} is Approved',
    message:
      'You claim request for the monthly target of {{month}} has been approved, and as a result the amount has been added to your wallet.',
  },
  updated: {
    title: 'Your Claim Request for {{month}} Reward is Updated',
    message:
      'You claim request for the monthly target of {{month}} has been updated, you can view the status of the application by the button below.',
  },
  rejected: {
    title: 'Your Monthly Reward for {{month}} is Rejected',
    message:
      'Your monthly reward has been rejected becuase you missed more than 4 daily upload for the Month Feb 2021, if you think that this has happened by mistake, then please contact us on writers@pocketfm.in.in',
  },
};

const mileStoneNotices = {
  approved: {
    title: 'Congratulations! Milestone {{milestone_no}} Achieved',
    message:
      'You have successfully completed {{word_count}} words. Your milestone reward has been added to your wallet',
  },
};

const withdrawalNotices = {
  applied: {
    title: 'Your Withdrawl Request is being processed',
    message:
      'Your withdrawal request of Rs. {{amount}} has been submitted and our team will update the status after reviewing it shortly.',
  },
  approved: {
    title: 'Your Withdrawl Request is Approved',
    message:
      'Your withdrawal request of Rs. {{amount}} has been approved and the amount has been transferred to you bank account which will be reflected in your bank account within 7-8 working days.',
  },
  rejected: {
    title: 'Your Withdrawal Request is Rejected',
    message:
      'Your withdrawal request of Rs. {{amount}} has been rejected and if you think that this has happened by mistake, then please contact us on writers@pocketfm.in',
  },
};

const promotional_text_pending = (
  <>
    Your application is under review. Meanwhile, keep adding more chapters to
    get more readers which will help us evaluate your novel earliest. Please
    note: Only<strong> Top 10% </strong>of the application gets selected for the
    writer's benefits program & It might take us 1 month or more to process.
  </>
);
const promotional_text_approved =
  'Congratulations, your application has been approved. {{amount}} has been successfully added to your wallet. Click the button to unlock monthly and milestones rewards.';
const promotional_text_rejected =
  'At this moment, we cannot sign your novel as it doesn’t meet our minimum performance guidelines. You can start a new novel, and again apply for the Writer’s Benefit Program.';
const promotional_text_updated =
  'At this moment, we cannot sign your novel as it doesn’t meet our minimum performance guidelines. However you can submit your application again after writing next 50,000 words. Please read the editorial feedback to improve your chances for selection.';

const promotional_text_unpublished =
  'Publish your novel to enter into Writer’s Benefit Program, and get a chance to earn upto Rs. {{amount}} annually';

const promotional_text_published =
  'Congratulations on publishing your novel. Write {{amount}} words to apply for the Writer’s Benefit Program.';

const promotional_text_eligible =
  'Congratulations on publishing your novel. You’re now eligble to apply for the Writer’s Benefit Program.';

const monthlyReward5000 =
  'You have completed the 50K monthly target of this month, you are now eligible for the Rs. 5000, you can go for 3000 as well';
const monthlyReward8000 =
  'You have completed the monthly target of this month, you are now eligible for the Rs. 8000';
const monthlyRewardText =
  'complete the 50K monthly target of this month and get eligible for the Rs. 5000, complete 80k and get eligible for 8000';
const videoTutorialSrc =
  'https://d2ps1cw166f1t3.cloudfront.net/e6875bd4490c2ee519cb8b5a7038627b81014a2a.mp4';
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const allLanguages = [
  { id: 1, name: 'hindi' },
  { id: 2, name: 'tamil' },
  { id: 3, name: 'bengali' },
  { id: 4, name: 'malayalam' },
  { id: 5, name: 'telugu' },
  { id: 6, name: 'marathi' },
  { id: 7, name: 'kannada' },
  { id: 8, name: 'english' },
  { id: 9, name: 'odia' },
  { id: 10, name: 'gujarati' },
];

const homePageContentAccordingToLocale = {
  IN: {
    createNewNovelCTAText: <>Create Novel &amp; Go To First Chapter</>,
    videoTutorialSrc:
      'https://d2ps1cw166f1t3.cloudfront.net/e6875bd4490c2ee519cb8b5a7038627b81014a2a.mp4',
    contestCreateNovelCTAText: 'Create Book and Start First Chapter',
    createNewNovelHeading: 'Create a New Novel',
    createContestNewNovelHeading: 'Create a New Novel for Contest',
    label: 'Title of the novel (you can update this later as well)',
  },
  US: {
    createNewNovelCTAText: <>Create Series &amp; Go to details</>,
    videoTutorialSrc:
      'https://d2ps1cw166f1t3.cloudfront.net/f06eba5e556716d93493a7aa3ae91f15ec55c561.mp4',
    contestCreateNovelCTAText: 'Create Series and Start First Chapter',
    createNewNovelHeading: 'Create a New Series',
    createContestNewNovelHeading: 'Create a New Series for Contest',
    label: 'Title of the Series (you can update this later as well)',
  },
  ROW: {
    createNewNovelCTAText: <>Create Series &amp; Go to details</>,
    videoTutorialSrc:
      'https://d2ps1cw166f1t3.cloudfront.net/f06eba5e556716d93493a7aa3ae91f15ec55c561.mp4',
    contestCreateNovelCTAText: 'Create Series and Start First Chapter',
    createNewNovelHeading: 'Create a New Series',
    createContestNewNovelHeading: 'Create a New Series for Contest',
    label: 'Title of the Series (you can update this later as well)',
  },
  INUGC: {
    createNewNovelCTAText: <>Create Series &amp; Go to details</>,
    videoTutorialSrc:
      'https://d2ps1cw166f1t3.cloudfront.net/fdbdbdb0ed6a1f25f9578cc2b4cb6d440c5410b2.mp4',
    contestCreateNovelCTAText: 'Create Series and Start First Chapter',
    createNewNovelHeading: 'Create a New Series',
    createContestNewNovelHeading: 'Create a New Series for Contest',
    label: 'Title of the Series (you can update this later as well)',
    guideLink:
      'https://pocketnovel.notion.site/pocketnovel/Functional-Guide-17b81968654c4805ae2ff23b0e21be45',
  },
};


const deleteChapterUIDs = [
  'd5f89c8b7e7585f07bc7fea4c325ca3e4aa47744', 
  '33a12f3f117d3b09f36e003de77ce4bea5f067cd', 
  'ee49a70707f7033fb13f93cf50b2d23234126b8c',
  'ed91ad0901ab14c27b288d0add556db8d8dc6f4b', 
  'cbe670866c07ce951c09af253f5528e9b07fe4e2', 
  'daa8141735fe7c2aa05d7a5c60bd64b2834409bb',
  'e4b489e4ab8e5e199515e484a1b260b1ce07e00c', 
  '470e37c0c3852b4c2e97708dbb15a61053b55d24', 
  '048d6b2f06302a772a18ab80e3d741140f455419',
  'ed43984d4a119532ae88ae2cc910863fed3ab743', 
  'd30d0788824306f510551c6eaebcf428c2f55f4e', 
  'b8c106b13b808ed3825130d7e8d519d1787c4078',
  'efd13d3c4435ac18e75576774ef3d1c6dbc9fb13', 
  '736851840701f94a154027c4b1bee08025de661b', 
  '093befe6eb18d4376a255ee13f172a0dd1d941f4'
]

export {
  deleteChapterUIDs,
  homePageContentAccordingToLocale,
  applicationUpdate,
  novelNotices,
  monthlyRewardText,
  monthlyReward5000,
  monthlyReward8000,
  rewardNotices,
  mileStoneNotices,
  withdrawalNotices,
  promotional_text_unpublished,
  promotional_text_published,
  promotional_text_eligible,
  promotional_text_pending,
  promotional_text_approved,
  promotional_text_rejected,
  promotional_text_updated,
  videoTutorialSrc,
  months,
  allLanguages,
};
