import React from "react";

function NewIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="17"
      fill="none"
      viewBox="0 0 37 17"
      {...props}
    >
      <path
        fill="#E9C86C"
        d="M0 2.72C0 1.218 1.506 0 3.364 0h30.272C35.494 0 37 1.218 37 2.72v11.56c0 1.502-1.506 2.72-3.364 2.72H3.364C1.506 17 0 15.782 0 14.28V2.72z"
      />
      <path
        fill="#3A2407"
        d="M12.676 13h-2.304L6.64 6.52h-.048a398.833 398.833 0 01.048 1.224c.016.2.028.404.036.612l.024.612V13H5.08V4.432h2.292l3.72 6.42h.036a353.793 353.793 0 00-.048-1.176c0-.2-.004-.396-.012-.588l-.024-.6V4.432h1.632V13zm7.098 0h-4.932V4.432h4.932V5.92h-3.12v1.884h2.904v1.488h-2.904V11.5h3.12V13zM32.08 4.432L29.897 13h-2.064l-1.164-4.5a10.39 10.39 0 01-.096-.396 12.606 12.606 0 00-.12-.576 33.69 33.69 0 01-.18-1.032 3.766 3.766 0 01-.072.444c-.032.184-.068.38-.108.588a20.853 20.853 0 01-.216.984L24.725 13H22.66l-2.184-8.568h1.788l1.092 4.68a28.053 28.053 0 01.336 1.728c.04.2.068.372.084.516a15.736 15.736 0 01.18-1.116l.108-.576c.04-.184.076-.328.108-.432l1.248-4.8h1.716l1.248 4.8a36.897 36.897 0 01.216 1.008 19.84 19.84 0 01.18 1.116c.024-.2.06-.436.108-.708.048-.28.1-.56.156-.84.064-.28.116-.512.156-.696l1.092-4.68h1.788z"
      />
    </svg>
  );
}

export default NewIcon;