import {
  compose,
  space,
  layout,
  flexbox,
  border,
  position,
  color,
  typography
} from 'styled-system';

export const typographyFunctions = compose(
  space,
  color,
  layout,
  flexbox,
  border,
  position,
  typography
);
