import axios from 'axios';

import {
  getDeviceDetails,
  getTokenAndUid,
  makePlatformStringFromDeviceInfo,
} from '../utils';

// const HOST = 'https://internalapi.pocketfm.com';
// const HOST = 'http://54.151.227.95'
const HOST =
  import.meta.env.VITE_APP_API_HOST ||
  'https://api-qa-cms2.pocketfm.com/nishant_rajput';

const apiVersion = 'v2';

const getDeviceHeaders = () => {
  let referrer =
    localStorage.getItem('referrer') !== 'undefined'
      ? localStorage.getItem('referrer')
      : '';
  const medium = sessionStorage.getItem('medium');
  const campaign = sessionStorage.getItem('campaign');
  const source = `${referrer}_medium_${medium || ''}_campaign_${campaign || ''}`;
  const locationData = JSON.parse(localStorage.getItem('locationData'));
  let deviceObj = {
    'device-id': localStorage.getItem('uuid'),
    ip: locationData?.ip,
    city: locationData?.city || 'Null',
    state: locationData?.state || 'Null',
    'app-version-code': import.meta.env.VITE_APP_VERSION_TAG || '',
    source: `${source}_${makePlatformStringFromDeviceInfo()}`,
    'app-build-number': localStorage.getItem('native_app_version') || 255,
    ...getDeviceDetails(),
  };
  return deviceObj || {};
};

const fetcher =
  (init, signal) =>
  (urlStr, paramObj = {}) => {
    let url = urlStr;
    if (urlStr && urlStr[0] === '/') {
      url = HOST + '/' + apiVersion + urlStr;
    }
    if (init.method !== 'get' && paramObj.params) {
      let querystring = '';
      for (const key in paramObj.params) {
        if (paramObj.params[key]) {
          querystring +=
            (querystring.length ? '&' : '') +
            key +
            '=' +
            encodeURIComponent(paramObj.params[key]);
        }
      }
      url = url + (querystring ? '?' + querystring : '');
      delete paramObj.params;
    }
    const headersOverrides = paramObj.headers || {};
    delete paramObj.headers;
    if (init.method === 'get') {
      paramObj.data = null;
    }
    let fixedHeaders = {
      // 'Content-Type': "application/x-www-form-urlencoded",
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'app-version': 180,
      'auth-token': 'web-auth',
      'app-client': 'consumer-web',
      'Web-Platform': 'writers',
    };
    if (!url.includes('logging_data/log')) {
      fixedHeaders = { ...fixedHeaders, ...getDeviceHeaders() };
    }
    const headers = { ...fixedHeaders, ...headersOverrides };
    const { token, uid } = getTokenAndUid();
    if (token) {
      headers['access-token'] = token;
      headers['uid'] = uid;
    }
    const finalAxiosObj = {
      url,
      headers,
      ...init,
      ...paramObj,
    };
    if (signal) {
      finalAxiosObj.cancelToken = signal.token;
    }
    return axios(finalAxiosObj)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error(
          'response data: ',
          error.response ? error.response.data : 'unknown'
        );
        console.error('stack: ', error.stack || 'undefined');
        if (axios.isCancel(error)) {
          // return new Promise(() => {})
          return Promise.reject({ message: 'cancelled' });
        }
        return Promise.reject({
          code: error && error.response && error.response.status,
          message:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message,
          url:
            (error &&
              error.response &&
              error.response.request &&
              error.response.request.responseURL) ||
            url,
          method: init.method,
        });
      });
  };

// use the following methods by passing relative url like '/user_api' or a complete url as the
// first param and param object as the second.
// the param object can contain params, data, headers objects

const get = fetcher({ method: 'get' });

const post = fetcher({ method: 'post' });

const put = fetcher({ method: 'put' });

const del = fetcher({ method: 'delete' });

const patch = fetcher({ method: 'patch' });

const cancellableAPI = () => {
  const signal = axios.CancelToken.source();
  return {
    get: fetcher({ method: 'get' }, signal),
    put: fetcher({ method: 'put' }, signal),
    post: fetcher({ method: 'post' }, signal),
    del: fetcher({ method: 'delete' }, signal),
    patch: fetcher({ method: 'patch' }, signal),
    cancelRequest: () => signal.cancel(),
  };
};

export { get, put, post, del, cancellableAPI, patch };
