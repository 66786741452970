import styled from 'styled-components';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.sf300};
  color: ${({ theme }) => theme.colors.text.text700};
  padding: 1rem;
`;

const StyledHeading = styled.p`
  margin: 8px auto;
`;

const StyledButton = styled.button`
  all: unset;
  border-radius: 4px;
  background-color: var(--azure-500);
  color: var(--white-op-88);
  font-size: 14px;
  padding: 10px 16px;
  width: 120px;
  margin-top: 16px;
`;
export const FallbackErrorUI = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <StyledContainer className="full-viewport-min-height text-align-center full-width v-center">
      <StyledHeading> Something Went Wrong, Please </StyledHeading>
      <StyledButton className="cursor-pointer" onClick={handleRetry}>
        Try again
      </StyledButton>
    </StyledContainer>
  );
};
