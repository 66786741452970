import React from 'react';

const BackButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props?.color || 'currentColor'}
      fillOpacity="1"
      fillRule="evenodd"
      d="M12.231 4.318c.377.403.355 1.036-.049 1.413L6.537 11H20c.552 0 1 .448 1 1s-.448 1-1 1H6.537l5.645 5.269c.404.377.426 1.01.05 1.413-.378.404-1.01.426-1.414.05l-7.5-7C3.115 12.541 3 12.276 3 12c0-.277.115-.542.318-.731l7.5-7c.403-.377 1.036-.355 1.413.049z"
      clipRule="evenodd"
    />
  </svg>
);

export default BackButton;
