import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIsMobile } from 'hooks/useIsMobile';
import { useControlsProviderStore } from 'providers/controlsProviderStore';
import { useNotifications } from 'services/notification';

import { defaultImageUrl, menuItemsArray } from '../../constants';
import { Consumer } from '../../context/editorContext';
import { get } from '../../lib/storage';
import {
  convertUrlToHttps,
  handleEventLog,
  handleFlutterCallbacks,
} from '../../lib/utils';
import { handleNewEventLog } from '../../lib/utils/events';
import { AccountContext } from '../../providers/accountProvider';
import { NotificationsContext } from '../../providers/notificationsProvider';
import Academy from '../Images/Academy';
import Analytics from '../Images/Analytics';
import AuthorBadge from '../Images/AuthorBadge';
import BackIcon from '../Images/BackButton';
import BellIcon from '../Images/BellIcon';
import CaretDown from '../Images/CaretDown';
import HomeIcon from '../Images/HomeIcon';
import LogoutIcon from '../Images/LogoutIcon';
import NewBrandLogoIcon from '../Images/NewBrandLogoIcon';
import NovelsIcon from '../Images/NovelsIcon';
import PenIcon from '../Images/Pen';
import PencilIcon from '../Images/PencilIcon';
import PlusWithFilledCircle from '../Images/PlusWithFilledCircle';
import WalletIcon from '../Images/WalletIcon';
import {
  BadgeImg,
  BadgeProfileContainer,
  BadgeProfileImg,
  BadgeProfileListItem,
  BadgeText,
  HeaderContainer,
  Main,
  MobileNavItemIcon,
  MobileNavItemText,
  MobileNavList,
  MobileNavListItem,
  NavButton,
  NavHeader,
  NavIconContainer,
  NavItemIcon,
  NavItemText,
  NavList,
  NavListItem,
  NavSideDrawer,
  NewVipIcon,
  NotificationBellContainer,
  NotificationCount,
  ProfileContainer,
  ProfileImg,
  ProfileImgContainer,
  ProfileListItem,
  ProfileMenuList,
  ProfileName,
  ProfileWalletContainer,
  UserProfileContainer,
} from './styles';

const mobileBreakPoint = 960; // 600px viewport width breakpoint

const NAV_ITEM_ICON = {
  home: <HomeIcon width="20" height="20" />,
  novels: <NovelsIcon width="20" height="20" />,
  my_creations: <PlusWithFilledCircle width="20" height="20" />,
  writers_benefit: <PenIcon width="20" height="20" />,
  income: <WalletIcon width="20" height="20" />,
  analytics: <Analytics width="20" height="20" />,
  writers_academy: <Academy width="20" height="20" />,
};

const DEVICE_TYPES = ['android', 'ios'];

const badgeIconObj = {
  l1: '/images/l1.svg',
  l2: '/images/l2.svg',
  certified: '/images/contracted.svg',
  rising_star: '/images/rising.svg',
  pro: '/images/pro.svg',
  silver: '/images/silver.svg',
  gold: '/images/gold.svg',
  platinum: '/images/platinum.svg',
};

const AuthorTierNotionPageHindi =
  'https://pocketnovel.notion.site/Writer-Advancement-Program-74383e57f29348f4b5d01d0053717ee6?pvs=4';
const AuthorTierNotionPageEnglish =
  'https://pocketnovel.notion.site/pocketnovel/Writer-Advancement-Program-d5c3c8c562784379a631126757b559fb';

const NavigationBarAndDrawer = ({
  children,
  isProfileNameShown = true,
  analytics_tab = false,
  tabFilterOnLanguageBasis = true,
  authorLanguage,
  badge,
  profileName = '',
}) => {
  const location = useLocation();
  const sourceApp = localStorage.getItem('source_app')?.toLowerCase();
  const { data, isError } = useNotifications();
  const profileImage = convertUrlToHttps(get('image_url'));
  const userToken = localStorage.getItem('token');
  const { user, isUgcUser, isHindiUgcUser, showNotifications } =
    useContext(AccountContext);
  // const isSideDrawerVisible = get("isSideDrawerVisible") ? (get("isSideDrawerVisible") === "true") : true
  const hasIncomePath = location.pathname.includes('income');
  const hasNotificationsPath = location.pathname.includes('notifications');
  const hasAddEditChapterPath =
    location.pathname.includes('edit-chapter') ||
    location.pathname.includes('add-chapter');
  const userProfileRef = useRef();
  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
  // const [isNavSideDrawerVisible, setIsNavSideDrawerVisible] = useState(isSideDrawerVisible)
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < mobileBreakPoint
  );

  const isMobile = useIsMobile();
  const process = sessionStorage.getItem('process');
  const { count } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const changeNavZIndex = useControlsProviderStore(
    (state) => state.changeNavZIndex
  );

  const hideProfileMenuHandler = (e) => {
    if (!userProfileRef) return;
    if (!userProfileRef.current) return;
    if (!userProfileRef.current.contains(e.target))
      setIsProfileMenuVisible(false);
  };

  const resizeWindowHandler = () =>
    setIsMobileView(window.innerWidth < mobileBreakPoint);

  useEffect(() => {
    window.addEventListener('click', hideProfileMenuHandler);
    window.addEventListener('resize', resizeWindowHandler);
    return () => {
      window.removeEventListener('click', hideProfileMenuHandler);
      window.removeEventListener('resize', resizeWindowHandler);
    };
  }, []);

  const handleLabel = (l) => {
    if (l === 'Novels' && isUgcUser) return 'Series';
    return l;
  };

  const handleNavClick = (label) => {
    if (label === 'novels') {
      handleNewEventLog(
        'nav_bar',
        'novels_novels_listing_page_clicked',
        {
          route: window.location.pathname + window.location.search,
          module_name: 'nav_bar',
        },
        user
      );
    } else if (label === 'home') {
      handleNewEventLog(
        'nav_bar',
        'writer_home_home_page_clicked',
        {
          route: window.location.pathname + window.location.search,
          module_name: 'nav_bar',
        },
        user
      );
    }
    //TODO: Uncomment this code after flutter integration
    // else if (label === 'income') {
    // handleFlutterCallbacks({
    //   method: 'earn',
    //   data: {
    //     user_id: user.uid,
    //     name: profileName,
    //     auth_token: localStorage.getItem('token'),
    //     location: user?.location,
    //   },
    //   elseDoThis: () => {},
    // });
    // }
  };

  const handleNotificationIconClicked = () => {
    handleNewEventLog(
      'notification',
      'notification_icon_clicked',
      {
        module_name: 'notificaton',
        notification_count: data.count || '0',
        author_tier: user.author_tier || 'N/A',
        timestamp: Date.now(),
      },
      user
    );
    const dataToSend = {
      method: 'notification',
      data: {
        user_id: user.uid,
        name: profileName,
        auth_token: userToken,
      },
    };
    if (window.FlutterCallback) {
      window.FlutterCallback.postMessage(JSON.stringify(dataToSend));
    } else console.log('FlutterCallback is not available');
  };

  const generateDesktopNavList = (menuItem, index) => {
    if (menuItem.slug === 'analytics' && !analytics_tab) return null;
    if (menuItem.slug === 'writers_benefit' && !tabFilterOnLanguageBasis)
      return null;
    if (
      menuItem.slug === 'writers_academy' &&
      authorLanguage !== 'hindi' &&
      menuItem.target === '_self'
    )
      return null;
    if (
      menuItem.slug === 'writers_academy' &&
      authorLanguage === 'hindi' &&
      menuItem.target === '_blank'
    )
      return null;
    if (menuItem.slug === 'novels' && isHindiUgcUser) return null;
    if (menuItem.slug === 'my_creations' && !isHindiUgcUser) return null;
    const hasSubMenu = menuItem?.submenu?.length > 0;
    return (
      <NavListItem
        key={index}
        to={!hasSubMenu && menuItem.link}
        as={hasSubMenu && 'div'}
        onClick={() => handleNavClick(menuItem.slug)}
        target={menuItem?.target}
      >
        <NavItemIcon>{NAV_ITEM_ICON[menuItem.slug]}</NavItemIcon>
        <NavItemText>{handleLabel(menuItem.label)}</NavItemText>
        {hasSubMenu &&
          menuItem.submenu.map((subMenuItem) => (
            <NavListItem key={menuItem.link} to={subMenuItem.link}>
              <NavItemText>{subMenuItem.label}</NavItemText>
            </NavListItem>
          ))}
      </NavListItem>
    );
  };

  const generateMobileNavList = (menuItem, index) => {
    if (
      menuItem.slug === 'income' ||
      menuItem.slug === 'analytics' ||
      menuItem?.submenu?.length > 0
    )
      return null;
    if (menuItem.slug === 'writers_benefit' && !tabFilterOnLanguageBasis)
      return null;
    if (
      menuItem.slug === 'writers_academy' &&
      authorLanguage !== 'hindi' &&
      menuItem.target === '_self'
    )
      return null;
    if (
      menuItem.slug === 'writers_academy' &&
      authorLanguage === 'hindi' &&
      menuItem.target === '_blank'
    )
      return null;
    if (menuItem.slug === 'novels' && isHindiUgcUser) return null;
    if (menuItem.slug === 'my_creations' && !isHindiUgcUser) return null;
    return (
      <MobileNavListItem
        key={index}
        to={menuItem.link}
        onClick={() => handleNavClick(menuItem.slug)}
        target={menuItem?.target}
      >
        <MobileNavItemIcon>{NAV_ITEM_ICON[menuItem.slug]}</MobileNavItemIcon>
        <MobileNavItemText>{handleLabel(menuItem.label)}</MobileNavItemText>
      </MobileNavListItem>
    );
  };

  const desktopNavList = menuItemsArray.map(generateDesktopNavList);
  const mobileNavList = menuItemsArray.map(generateMobileNavList);

  const toggleProfileMenuHandler = () =>
    setIsProfileMenuVisible((prevValue) => !prevValue);

  const goBackHandler = () => {
    console.log('back button pressed');
    if (
      (window.Android && location?.hash === '#redirectFromLogin') ||
      process === 'continue-writing ' ||
      process === 'create-new' ||
      sessionStorage.getItem('campaign')?.includes('bingerinvite')
    ) {
      if (window.Android.closePage) {
        console.log('Executed closePage');
        window.Android.closePage();
      }
      handleFlutterCallbacks({
        method: 'closePage',
        data: {},
        elseDoThis: () => {},
      });
    }
    if (window.Android && location?.hash === '#redirectFromLogin') {
      if (window.Android.onBackPressed) {
        console.log('Executed onBackPressed');
        window.Android.onBackPressed();
      }
    } else if (window.webkit && location?.hash === '#redirectFromLogin') {
      if (
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.writerBackPressed
      ) {
        console.log('writerBackPressed webkit.messageHandlers');
        window.webkit.messageHandlers.writerBackPressed.postMessage(
          'writerBackPressed'
        );
      }
    } else navigate(-1);
  };

  const goToHomeHandler = () => navigate('/home');

  const goToIncomeHandler = () => {
    navigate('/income');
    //TODO: Uncomment this code after flutter integration
    // handleFlutterCallbacks({
    //   method: 'earn',
    //   data: {
    //     user_id: user.uid,
    //     name: profileName,
    //     auth_token: localStorage.getItem('token'),
    //     location: user?.location,
    //   },
    //   elseDoThis: () => navigate('/income'),
    // });
  };

  const generateNavHeader = (editorControlsComponents) => (
    <>
      <HeaderContainer>
        {isMobile ? (
          !hasAddEditChapterPath && (
            <NavIconContainer>
              <NavButton onClick={goBackHandler} title="Back">
                <BackIcon />
              </NavButton>
              <NewBrandLogoIcon
                onClick={goToHomeHandler}
                title="Pocket Writer"
              />
            </NavIconContainer>
          )
        ) : (
          <NavIconContainer>
            {!hasAddEditChapterPath && (
              <NavButton onClick={goBackHandler} title="Back">
                <BackIcon />
              </NavButton>
            )}
            {!hasAddEditChapterPath && (
              <NewBrandLogoIcon
                onClick={goToHomeHandler}
                title="Pocket Writer"
              />
            )}
          </NavIconContainer>
        )}
        {editorControlsComponents()}
        {!hasAddEditChapterPath && isProfileNameShown && (
          <ProfileContainer>
            {DEVICE_TYPES.includes(sourceApp) &&
              isMobileView &&
              showNotifications && (
                <NotificationBellContainer
                  count={data?.count}
                  isActive={hasNotificationsPath}
                  onClick={handleNotificationIconClicked}
                >
                  <BellIcon />
                  {data?.count > 0 && (
                    <NotificationCount>
                      {data?.count > 99 ? '99+' : data.count}
                    </NotificationCount>
                  )}
                </NotificationBellContainer>
              )}
            {isMobileView && !hasAddEditChapterPath && (
              <ProfileWalletContainer
                isActive={hasIncomePath}
                onClick={goToIncomeHandler}
              >
                <WalletIcon />
              </ProfileWalletContainer>
            )}
            <UserProfileContainer
              onClick={toggleProfileMenuHandler}
              ref={userProfileRef}
            >
              {isMobileView && badge && (
                <BadgeText>{badge?.split('_').join(' ')}</BadgeText>
              )}
              {!badge ? (
                <ProfileImgContainer>
                  {profileImage && (
                    <ProfileImg
                      src={profileImage}
                      alt="Writer Avator"
                      onError={(e) => {
                        e.target.src = defaultImageUrl;
                      }}
                    />
                  )}
                </ProfileImgContainer>
              ) : (
                <BadgeProfileContainer>
                  <BadgeImg src={badgeIconObj[badge]} />
                  {profileImage && (
                    <BadgeProfileImg src={profileImage} alt="Writer Avator" />
                  )}
                  {!isMobileView && (
                    <BadgeText>{badge?.split('_').join(' ')}</BadgeText>
                  )}
                </BadgeProfileContainer>
              )}

              {!isMobileView && <ProfileName>{profileName}</ProfileName>}
              {!isMobileView && <CaretDown width="15" height="15" />}
              <ProfileMenuList isVisible={isProfileMenuVisible} badge={badge}>
                {badge && (
                  <BadgeProfileListItem
                    href={
                      authorLanguage === 'hindi'
                        ? AuthorTierNotionPageHindi
                        : AuthorTierNotionPageEnglish
                    }
                    target="_blank"
                    onClick={() =>
                      handleEventLog('author_tier', 'button_click', {
                        route:
                          window.location.pathname + window.location.search,
                        button: 'menu_author_tier_button',
                      })
                    }
                  >
                    <NewVipIcon />
                    <AuthorBadge width="20" height="20" />
                    <span>Author Tier</span>
                  </BadgeProfileListItem>
                )}
                <ProfileListItem to="/edit-profile">
                  <PencilIcon width="20" height="20" />
                  <span>Edit Profile</span>
                </ProfileListItem>
                {window.screen.width < 600 && analytics_tab && (
                  <ProfileListItem to="/analytics">
                    <Analytics width="20" height="20" />
                    <span>Analytics</span>
                  </ProfileListItem>
                )}
                <ProfileListItem to="/logout">
                  <LogoutIcon width="20" height="20" />
                  <span>Logout</span>
                </ProfileListItem>
              </ProfileMenuList>
            </UserProfileContainer>
          </ProfileContainer>
        )}
      </HeaderContainer>
      {isMobileView && !hasAddEditChapterPath && (
        <MobileNavList>{mobileNavList}</MobileNavList>
      )}
    </>
  );

  return (
    <Consumer>
      {(editorControlsComponents) => (
        <>
          <NavHeader
            hasAddEditChapterPath={hasAddEditChapterPath}
            changeNavZIndex={changeNavZIndex}
          >
            {generateNavHeader(editorControlsComponents)}
          </NavHeader>
          <NavSideDrawer isVisible={!hasAddEditChapterPath}>
            <NavList>{desktopNavList}</NavList>
          </NavSideDrawer>
          <Main
            hasAddEditChapterPath={hasAddEditChapterPath}
            isMobileView={isMobileView}
          >
            {children}
          </Main>
        </>
      )}
    </Consumer>
  );
};

export default NavigationBarAndDrawer;
