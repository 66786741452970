import { useQuery } from '@tanstack/react-query';

import { getNotificationCount } from 'services/api';

export function useNotifications() {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: getNotificationCount,
    refetchInterval: 900000, // 15 mins
  });
}
