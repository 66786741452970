import React from 'react';

const NewBrandLogoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="19"
    viewBox="0 0 68 19"
    fill="none"
  >
    <path
      d="M4.53407 17.8H8.60635L11.2702 9.025H11.3927L14.0565 17.8H18.1288L22.7522 2.09091H18.0063L15.863 11.7864H15.7405L13.1992 2.09091H9.46368L6.92232 11.7557H6.79985L4.65654 2.09091H-0.0893555L4.53407 17.8Z"
      fill="#068AE2"
    />
    <path
      d="M23.2389 17.8H27.4643V11.6636C27.4643 10.3136 28.3905 9.42387 29.6383 9.42387C30.0746 9.42387 30.7788 9.4929 31.1998 9.63864V6.02586C30.863 5.92614 30.4956 5.86478 30.1282 5.86478C28.8728 5.86478 27.893 6.60114 27.4643 8.25796H27.3418V6.01818H23.2389V17.8Z"
      fill="#068AE2"
    />
    <path
      d="M32.3697 17.8H36.5951V6.01818H32.3697V17.8ZM34.4824 4.79091C35.6306 4.79091 36.5645 3.92415 36.5645 2.85796C36.5645 1.79176 35.6306 0.925003 34.4824 0.925003C33.3342 0.925003 32.4004 1.79176 32.4004 2.85796C32.4004 3.92415 33.3342 4.79091 34.4824 4.79091Z"
      fill="#068AE2"
    />
    <path
      d="M45.5441 6.01818H43.5233V3.19546H39.2979V6.01818H37.7976V9.08637H39.2979V14.4097C39.2673 16.8795 40.7906 18.1298 43.707 17.9688C44.6638 17.9151 45.3757 17.7156 45.7585 17.6006L45.1461 14.6244C44.9853 14.6628 44.572 14.7472 44.3194 14.7472C43.7682 14.7472 43.5233 14.5017 43.5233 14.0108V9.08637H45.5441V6.01818Z"
      fill="#068AE2"
    />
    <path
      d="M52.5603 18.0148C55.8212 18.0148 57.9186 16.45 58.3166 13.9955H54.4587C54.2137 14.6628 53.5171 15.0386 52.6522 15.0386C51.3968 15.0386 50.662 14.2102 50.662 13.075V12.8909H58.3166V11.9091C58.3166 8.15824 56.0202 5.86478 52.4685 5.86478C48.8248 5.86478 46.4978 8.28864 46.4978 11.9398C46.4978 15.752 48.7942 18.0148 52.5603 18.0148ZM50.662 10.5591C50.6849 9.53892 51.5269 8.84091 52.5603 8.84091C53.609 8.84091 54.4051 9.54659 54.4281 10.5591H50.662Z"
      fill="#068AE2"
    />
    <path
      d="M59.8253 17.8H64.0507V11.6636C64.0507 10.3136 64.9769 9.42387 66.2246 9.42387C66.6609 9.42387 67.3651 9.4929 67.7861 9.63864V6.02586C67.4493 5.92614 67.0819 5.86478 66.7145 5.86478C65.4591 5.86478 64.4793 6.60114 64.0507 8.25796H63.9282V6.01818H59.8253V17.8Z"
      fill="#068AE2"
    />
  </svg>
);

export default NewBrandLogoIcon;
