import React, { useState } from 'react';

import { get } from '../lib/api';

const AccountContext = React.createContext({
  user: {},
  onUpdateUser: () => {},
});

const AccountProvider = ({ userData, children }) => {
  const [user, updateUser] = useState(userData || {});
  const [isUgcUser, setIsUgcUser] = useState(false);
  const [isHindiUgcUser, setIsHindiUgcUser] = useState(false);
  const [updateChapter, setUpdateChapter] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [newChapterCreationFlow, setNewChapterCreationFlow] = useState(true);
  const [wbpContractWithPerfios, setWbpContractWithPerfieos] = useState(false);
  const SOURCE_APP_NOVELS = ['android', 'ios'];
  const SOURCE_APP_FM = ['pfm_android', 'pfm_ios'];
  const sourceApp = localStorage.getItem('source_app');

  const handleUpdateUser = (data) => {
    updateUser(data);
    const uid = data?.uid || localStorage.getItem('uid');
    if (!SOURCE_APP_NOVELS.includes(sourceApp)) {
      // means user is coming from FM app or web
      get(`/user_api/user.is_hindi_ugc_beta_user?uid=${uid}&is_novel=1`).then(
        (res) => {
          if (SOURCE_APP_FM.includes(sourceApp) && res?.beta_user === 1) {
            // if user is coming from FM
            setIsUgcUser(true);
            setIsHindiUgcUser(false);
          } else {
            // user is coming from web
            setIsHindiUgcUser(
              res?.beta_user === 1 &&
                data?.location &&
                data?.location === 'IN' &&
                data?.languages === 'hindi'
            );
            setIsUgcUser(data?.location && data?.languages === 'english');
          }
        }
      );
    } else setIsUgcUser(data?.location && data?.languages === 'english');

    get(
      '/user_api/user.is_beta_release_enabled?is_novel=1&feature_name=chapter_creation_v3'
    )
      .then((res) => setNewChapterCreationFlow(res?.enable_chapter_creation_v3))
      .catch((error) => {
        console.log(error);
      });
    get(
      '/user_api/user.is_beta_release_enabled?is_novel=1&feature_name=update_chapter'
    )
      .then((res) => setUpdateChapter(res?.enable_update_chapter))
      .catch((error) => {
        console.log(error);
      });
    get(
      '/user_api/user.is_beta_release_enabled?is_novel=1&feature_name=show_notifications'
    )
      .then((res) => setShowNotifications(res?.enable_show_notifications))
      .catch((error) => {
        console.log(error);
      });
    get(
      '/user_api/user.is_beta_release_enabled?is_novel=1&feature_name=perfios_integration'
    )
      .then((res) =>
        setWbpContractWithPerfieos(res?.enable_perfios_integration)
      )
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AccountContext.Provider
      value={{
        user,
        isUgcUser,
        isHindiUgcUser,
        onUpdateUser: handleUpdateUser,
        updateChapter,
        newChapterCreationFlow,
        wbpContractWithPerfios,
        showNotifications,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext };
export default AccountProvider;
