import React from 'react'

const HomeIcon = (props) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 1.162 1.086 11.566a.6.6 0 1 0 .828.868l.986-.939V21A1.1 1.1 0 0 0 4 22.1h5a1.1 1.1 0 0 0 1.1-1.1v-4.4h3.8V21a1.1 1.1 0 0 0 1.1 1.1h5a1.1 1.1 0 0 0 1.1-1.1v-9.051l.476.475a.6.6 0 0 0 .848-.848L12.01 1.162z"
        fill="currentColor"
      />
    </svg>
  )


export default HomeIcon
