import React from 'react';

import { IconPropsType } from 'designSystem/types/icons';

interface ISuccessIconProps extends IconPropsType {}

const SuccessIcon = ({
  size = 16,
  color = '#28a745',
  ...props
}: ISuccessIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2605 26.6667C11.7195 26.6667 11.1779 26.4516 10.7651 26.021L3.28622 18.2256C2.4601 17.3645 2.4601 15.9686 3.28622 15.1076C4.11234 14.2465 5.45152 14.2465 6.27764 15.1076L12.2605 21.3437L25.7223 7.31238C26.5485 6.45156 27.888 6.4513 28.7135 7.31238C29.5399 8.17347 29.5399 9.56965 28.7135 10.4304L13.7563 26.021C13.3432 26.4513 12.8019 26.6667 12.2605 26.6667Z"
      fill={color}
    />
  </svg>
);

export default SuccessIcon;
