import React from 'react';

const Headphone = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5458 2C7.421 2 3.09154 6.17116 3.09154 11.1085C3.09154 13.8921 4.47397 17.2167 6.61331 19.5784L6.67378 19.6451L6.76196 19.6277C6.78449 19.6236 7.27448 19.5202 7.47923 19.1455C7.98111 19.5568 8.42672 19.5971 8.89548 19.4291C9.1039 19.8302 9.18491 20.2668 8.6263 20.4252C8.10042 20.5749 7.3977 20.3806 6.87882 20.3019C6.01421 20.1706 4.18226 20.1326 3.30897 20.1651C2.89775 20.1806 2.89628 20.8204 3.30897 20.8048C4.36326 20.7658 6.34438 20.8703 7.38743 21.0184C7.90932 21.0926 8.57561 21.1953 9.07587 20.9523C9.94007 20.5324 9.79452 19.8164 9.44687 19.1534C9.70781 18.9942 9.98616 18.7931 10.2878 18.5769C9.45135 18.2095 8.35615 16.5658 7.60811 15.1326C7.07881 14.1179 6.04338 11.9675 6.16012 10.8806C5.51359 11.3246 5.01057 11.7113 4.73764 12.2186C4.6621 11.7401 4.62143 11.2704 4.62143 10.8157C4.62143 6.90327 8.71884 4.27516 12.5455 4.27516C17.2856 4.27516 20.4692 7.65717 20.4692 10.8157C20.4692 11.2677 20.4326 11.7307 20.3622 12.1992C20.0874 11.7013 19.5885 11.3186 18.9502 10.8806C19.0671 11.9675 18.0319 14.1179 17.5024 15.1326C16.7546 16.5658 15.6591 18.2098 14.8228 18.5769C15.972 19.4015 16.7421 19.981 17.7643 19.0286C17.9734 19.3535 18.3536 19.524 18.373 19.5321L18.479 19.5777L18.5557 19.4915C20.6485 17.1386 22 13.8483 22 11.1081C22 6.17075 17.6705 2 12.5458 2Z"
      fill={props?.fill || '#0D1536'}
      fillOpacity="1"
    />
    <path
      d="M6.76428 10.5535C6.72394 10.5535 6.68836 10.5629 6.65536 10.5775C6.64367 10.5826 6.63087 10.5863 6.61991 10.5934C6.15511 10.8977 6.78041 12.8519 7.89301 14.9841C8.90831 16.9299 10.0615 18.3339 10.6089 18.3339C10.6191 18.3339 10.6282 18.331 10.6379 18.3301C10.6782 18.3257 10.7163 18.3158 10.749 18.2944C10.7848 18.2707 10.8129 18.2341 10.8372 18.1914C10.8385 18.1889 10.8398 18.1854 10.841 18.183C11.1215 17.662 10.5976 15.8404 9.57628 13.8829C8.56224 11.9394 7.32716 10.5531 6.76428 10.5535Z"
      fill={props?.fill || '#0D1536'}
      fillOpacity="1"
    />
    <path
      d="M17.2186 14.9841C18.3311 12.8519 18.9563 10.8974 18.4916 10.5934C18.4807 10.5864 18.4678 10.5826 18.456 10.5775C18.4232 10.563 18.3876 10.5535 18.3473 10.5535C17.7847 10.5535 16.5492 11.9394 15.5352 13.8829C14.514 15.8407 13.9898 17.6624 14.2704 18.1832C14.2716 18.1857 14.2731 18.1889 14.2745 18.1913C14.2985 18.2344 14.3269 18.2713 14.3625 18.2943C14.3959 18.3162 14.4346 18.3268 14.476 18.3306C14.4846 18.3316 14.4931 18.3344 14.5027 18.3344C15.0498 18.3344 16.2031 16.9299 17.2186 14.9841Z"
      fill={props?.fill || '#0D1536'}
      fillOpacity="1"
    />
    <path
      d="M13.0402 5.81512C12.7097 5.75884 12.4395 6.00214 12.4395 6.33741V9.8008C12.2442 9.72912 12.0337 9.68789 11.8132 9.68789C10.8069 9.68789 9.99121 10.5036 9.99121 11.5101C9.99121 12.5163 10.8069 13.332 11.8132 13.332C12.5507 13.332 13.1848 12.894 13.4713 12.2642C13.6104 11.9594 13.6303 11.4089 13.6316 11.0739C13.6354 10.0023 13.6354 7.88727 13.6354 7.88727C15.9894 7.88727 15.8483 9.29802 15.5727 10.1082C15.4649 10.4256 15.5291 10.4786 15.7559 10.2317C18.6285 7.1026 14.6287 6.08547 13.0402 5.81512Z"
      fill={props?.fill || '#0D1536'}
      fillOpacity="1"
    />
  </svg>
);

export default Headphone;
