import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import NewIcon from '../Images/NewIcon';

function getStylesForEditorPage(hasAddEditChapterPath, isMobileView) {
  if (hasAddEditChapterPath) {
    return isMobileView ? '44px' : '56px';
  }
  return `122px`;
}

function getZIndexForSharePopup(
  hasAddEditChapterPath,
  isMobileView,
  changeNavZIndex
) {
  if (hasAddEditChapterPath) {
    return isMobileView && changeNavZIndex ? '3600' : '2';
  }
  return '3000';
}

function getZindexMobileView(hasAddEditChapterPath, changeNavZIndex) {
  if (hasAddEditChapterPath) {
    return changeNavZIndex ? '3600' : '1';
  }
  return '3000';
}

const Main = styled.main`
  flex-grow: 1;
  min-height: ${(props) =>
    props.hasAddEditChapterPath && props.isMobileView ? '0' : '100vh'};
  margin-top: 41px;
  padding: 16px 0px;
  /* background-color: ${({ theme }) => theme.colors.bg.primary}; */
  background-color: ${({ theme }) => theme.colors.surface.sf300};
  background-color: ${({ hasAddEditChapterPath, theme }) =>
    hasAddEditChapterPath
      ? theme.colors.bg.primary
      : theme.colors.surface.sf300};
  width: calc(100% - 240px);
  transition: width 0.2s;

  @media (max-width: 960px) {
    margin-top: ${({ hasAddEditChapterPath, isMobileView }) =>
      getStylesForEditorPage(hasAddEditChapterPath, isMobileView)};
    /* margin-top: ${({ hasAddEditChapterPath }) =>
      hasAddEditChapterPath ? '56px' : '101px'}; */
    width: 100%;
  }
`;

const NavHeader = styled.header`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  flex-shrink: 0;
  z-index: ${(props) =>
    getZIndexForSharePopup(
      props.hasAddEditChapterPath,
      props.isMobileView,
      props.changeNavZIndex
    )};
  /* z-index: 3000; */
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  color: ${({ theme }) => theme.colors.text.text700};
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.text.textMuted16};
  min-height: 56px;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  transition: all 0.2s;

  @media (max-width: 960px) {
    flex-direction: column;
    color: var(--Text500);
    padding: 0px;
  }

  @media (max-width: 600px) {
    z-index: ${(props) =>
      getZindexMobileView(props.hasAddEditChapterPath, props.changeNavZIndex)};
  }
`;

const NewVipIcon = styled(NewIcon)`
  position: absolute;
  top: -8px;
  left: -12px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.text700};
  transition: all 0.2s;
  margin-bottom: 8px;
  @media (max-width: 960px) {
    padding: 0px 20px;
    margin-top: 8px;
  }
`;

const MobileNavList = styled.ul`
  border-top: 1px solid ${({ theme }) => theme.colors.surface.sf900};
  list-style: none;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.text700};
  display: flex;
  justify-content: space-evenly;
  padding: 12px 20px;
`;

const MobileNavListItem = styled(NavLink)`
  font-weight: normal;
  transition: all 0.2s;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;
  &.active {
    color: var(--Azure500);
  }
  &.last-child {
    margin-right: 0;
  }
`;

const MobileNavItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const MobileNavItemText = styled.p`
  font-size: 14px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
`;

const UserProfileContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: inherit;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

const ProfileImgContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  overflow: hidden;
  box-shadow: inset 0 0 4px 0 ${({ theme }) => theme.colors.text.textMuted16};
  transition: all 0.2s;

  @media (max-width: 960px) {
    margin-right: 0px;
    width: 28px;
    height: 28px;
  }
`;

const BadgeProfileContainer = styled.div`
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: 960px) {
    margin-right: 0px;
  }
  text-align: center;
`;

const BadgeImg = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
`;

const BadgeProfileImg = styled.img`
  border-radius: 50%;
  height: 24px;
  width: 24px;
`;

const BadgeText = styled.div`
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  margin-top: 2px;
  width: 90px;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #f7737b 0%,
    #799e22 67%,
    #068ae2 100%
  );
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  @media (max-width: 360px) {
    font-size: 3.3vw;
  }
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
`;

const ProfileWalletContainer = styled.div`
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  color: ${({ isActive }) => (isActive ? 'var(--Azure500)' : 'inherit')};
`;

const bellRingAnimation = keyframes`
  0% { transform: rotate(0deg); }
  7.5% { transform: rotate(25deg); }
  30% { transform: rotate(-25deg); }
  45% { transform: rotate(15deg); }
  58% { transform: rotate(-10deg); }
  70% { transform: rotate(5deg); }
  87.5% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
`;

const NotificationBellContainer = styled.div`
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  position: relative;
  color: ${({ isActive }) => (isActive ? 'var(--Azure500)' : 'inherit')};
  svg {
    animation: ${bellRingAnimation} 1.4s infinite ease-in-out;
    animation: ${({ count }) => count <= 0 && 'none'};
    transform-origin: top;
  }
`;

const NotificationCount = styled.span`
  background-color: var(--Crimson500);
  color: var(--Dove);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -4px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
`;

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border-radius: 50%;
`;

const ProfileName = styled.p`
  margin-right: 8px;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

const ProfileMenuList = styled.ul`
  width: 160px;
  padding: 16px;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  border-radius: 2px;
  position: absolute;
  top: ${({ badge }) => (badge ? 'calc(100% + 16px)' : 'calc(100% + 8px)')};
  right: 0px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  list-style: none;
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.text.textMuted16};
`;

const ProfileListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  color: inherit;

  svg {
    margin-right: 8px;
  }
`;

const BadgeProfileListItem = styled.a`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  color: inherit;
  svg {
    margin-right: 8px;
  }
`;

const NavSideDrawer = styled.nav`
  width: 240px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.surface.foreground};
  color: ${({ theme }) => theme.colors.text.text700};
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.text.textMuted16};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

  @media (max-width: 960px) {
    display: none;
  }
`;

const NavIconContainer = styled.div`
  color: inherit;
  display: flex;
  align-items: center;
  height: 56px;

  svg:last-of-type {
    height: 28px;
    transition: all 0.2s;
  }

  @media (max-width: 960px) {
    svg:last-of-type {
      height: 25px;
    }
  }
`;

const NavButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;
  margin-right: 16px;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  transition: all 0.2s;

  @media (max-width: 960px) {
    margin-right: 8px;
  }
`;

const NavList = styled.ul`
  padding: 96px 10px 0px 10px;
  list-style: none;
  width: inherit;
  background-color: inherit;
  position: fixed;
  left: 0px;
  top: 0px;
  overflow-y: auto;
  z-index: 1100;
  height: 100%;
  box-shadow: inherit;
`;

const NavListItem = styled(NavLink)`
  display: flex;
  background-color: transparent;
  padding: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  align-items: center;
  box-shadow: none;
  border: 1px solid transparent;
  color: inherit;
  transition: all 0.2s;
  :hover {
    background-color: #0000000d;
    background-color: ${({ theme }) => theme.colors.surface.sf300};
  }
  &.active {
    border-color: var(--Azure500);
    box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.text.textMuted16};
  }
  &.active:hover {
    background-color: transparent;
  }
`;

const NavItemText = styled.p`
  font-size: 16px;
`;

const NavItemIcon = styled.div`
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Main,
  MobileNavList,
  MobileNavListItem,
  MobileNavItemIcon,
  MobileNavItemText,
  ProfileContainer,
  UserProfileContainer,
  ProfileImgContainer,
  ProfileWalletContainer,
  NotificationBellContainer,
  NotificationCount,
  ProfileImg,
  ProfileName,
  ProfileMenuList,
  ProfileListItem,
  BadgeProfileListItem,
  NavHeader,
  HeaderContainer,
  NavSideDrawer,
  NavIconContainer,
  NavList,
  NavListItem,
  NavItemText,
  NavItemIcon,
  NavButton,
  BadgeProfileContainer,
  BadgeProfileImg,
  BadgeText,
  BadgeImg,
  NewVipIcon,
};
