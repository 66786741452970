const dummy_entity_id_for_all_book = 'dummy_entity_id_for_all_book';
const cautionText1Msg =
  'For some book’s contract process is in review. All rewards will be transferred to you, by 15th of the subsequent Month post-confirmation';
const cautionText2Msg =
  'Payment cycle for crediting reward will be within 2nd week of every Month';
const cautionText3Msg =
  'Contract process is in review. All rewards will be transferred to you, by 15th of the subsequent Month post-confirmation';

const cautionNoteObj = {
  upcoming_payout: {
    pre_payout:
      'Only books which are contract reviewed last month or before will be eligible',
    post_payout:
      'Only books which are contract reviewed current month or before will be eligible',
  },
  last_payout:
    'We are noticing some issue with our payment partner while crediting amount to your bank. Worry not, we are on it!',
  overall:
    'Payment cycle will be on 15th of every month given your book’s contract was reviewed the month before',
};
export {
  dummy_entity_id_for_all_book,
  cautionText1Msg,
  cautionText2Msg,
  cautionText3Msg,
  cautionNoteObj,
};
