import React from 'react'

const LogoutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
    <path fill="currentColor" fillOpacity=".65" d="M14.37 6.336c-.266-.266-.698-.266-.964 0s-.266.698 0 .964l2.018 2.018H7.07c-.377 0-.682.305-.682.682 0 .377.305.682.682.682h8.354L13.406 12.7c-.266.266-.266.698 0 .964.133.133.307.2.482.2s.349-.067.482-.2l3.182-3.182c.266-.266.266-.698 0-.964L14.37 6.336z"/>
    <path fill="currentColor" fillOpacity=".65" d="M10.252 17.5c.377 0 .682-.305.682-.682 0-.376-.305-.682-.682-.682H5.025V3.864h5.227c.377 0 .682-.306.682-.682 0-.377-.305-.682-.682-.682h-5.91c-.376 0-.68.305-.68.682v13.636c0 .377.304.682.681.682h5.909z"/>
  </svg>
)

export default LogoutIcon
