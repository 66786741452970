import { Toaster } from 'sonner';
import styled from 'styled-components';

import ErrorIcon from 'designSystem/icons/Iconography/ErrorIcon';
import SuccessIcon from 'designSystem/icons/Iconography/SuccessIcon';

const StyledToaster = styled(Toaster)`
  button[aria-label='Close toast'] {
    right: 0;
    left: unset;
  }
`;

export default function AlertSnackBarWrapper() {
  return (
    <StyledToaster
      icons={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
      }}
      position="bottom-center"
    />
  );
}
