import React from 'react';

function Academy(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.25 19.5C22.8 19.5 22.5 19.2 22.5 18.75V9C22.5 8.55 22.8 8.25 23.25 8.25C23.7 8.25 24 8.55 24 9V18.75C24 19.2 23.7 19.5 23.25 19.5Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        d="M12 15.7499C11.85 15.7499 11.775 15.7499 11.625 15.6749L0.375 9.6749C0.15 9.5249 0 9.2999 0 8.9999C0 8.6999 0.15 8.4749 0.375 8.3249L11.625 2.3249C11.85 2.1749 12.075 2.1749 12.3 2.3249L23.55 8.3249C23.775 8.4749 23.925 8.6999 23.925 8.9999C23.925 9.2999 23.775 9.5249 23.55 9.6749L12.3 15.6749C12.225 15.7499 12.15 15.7499 12 15.7499Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        d="M13.05 16.95C12.75 17.175 12.375 17.25 12 17.25C11.625 17.25 11.25 17.175 10.95 16.95L4.5 13.575V16.5C4.5 18.825 8.175 21 12 21C15.825 21 19.5 18.825 19.5 16.5V13.575L13.05 16.95Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default Academy;
