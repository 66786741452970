import React from 'react';

const PlusWithFilledCircle = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 2443">
      <g id="Group 3214">
        <path
          id="Ellipse 4"
          d="M14.4 7.99998C14.4 11.5346 11.5346 14.4 7.99998 14.4C4.46535 14.4 1.59998 11.5346 1.59998 7.99998C1.59998 4.46535 4.46535 1.59998 7.99998 1.59998C11.5346 1.59998 14.4 4.46535 14.4 7.99998Z"
          fill="currentColor"
        />
        <path
          id="Ellipse 4 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4Z"
          fill="currentColor"
        />
        <g id="Group 2442">
          <g id="Group 3213">
            <path
              id="Vector 10 (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.41821 11.438V4.56195H8.58185V11.438H7.41821Z"
              fill="white"
            />
            <path
              id="Vector 11 (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.4379 8.58185H4.56189V7.41821H11.4379V8.58185Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PlusWithFilledCircle;
