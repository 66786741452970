import React from 'react';

import Headphone from '../components/Images/Headphone';
import NovelsIcon from '../components/Images/NovelsIcon';

const audiobooks = [
  { name: 'Self Help Audiobook', slug: 'selfHelfBook' },
  { name: 'Society and Culture', slug: 'societyAndCulture' },
  {
    name: 'Mythological Thriller Audiobooks',
    slug: 'mythologicalThrillerAudiobooks',
  },
  { name: 'Suspense and Thriller', slug: 'suspenseAndThriller' },
  { name: 'Horror Audiobook', slug: 'horrorAudiobook' },
  { name: 'Crime Audiobooks', slug: 'crimeAudiobooks' },
  { name: 'Fantasy Audiobooks', slug: 'fantasyAudiobooks' },
  { name: 'Classic Audiobooks', slug: 'classicAudiobooks' },
  { name: 'Stories', slug: 'stories' },
  { name: 'Romantic Audiobook', slug: 'romanticAudiobook' },
];

const languages = [
  {
    name: 'Abkhazian',
    id: 'abkhazian',
  },
  {
    name: 'Achinese',
    id: 'achinese',
  },
  {
    name: 'Acoli',
    id: 'acoli',
  },
  {
    name: 'Adangme',
    id: 'adangme',
  },
  {
    name: 'Adyghe',
    id: 'adyghe',
  },
  {
    name: 'Afar',
    id: 'afar',
  },
  {
    name: 'Afrihili',
    id: 'afrihili',
  },
  {
    name: 'Afrikaans',
    id: 'afrikaans',
  },
  {
    name: 'Aghem',
    id: 'aghem',
  },
  {
    name: 'Ainu',
    id: 'ainu',
  },
  {
    name: 'Akan',
    id: 'akan',
  },
  {
    name: 'Akkadian',
    id: 'akkadian',
  },
  {
    name: 'Akoose',
    id: 'akoose',
  },
  {
    name: 'Alabama',
    id: 'alabama',
  },
  {
    name: 'Albanian',
    id: 'albanian',
  },
  {
    name: 'Aleut',
    id: 'aleut',
  },
  {
    name: 'Algerian Arabic',
    id: 'algerian arabic',
  },
  {
    name: 'American Sign Language',
    id: 'american sign language',
  },
  {
    name: 'Amharic',
    id: 'amharic',
  },
  {
    name: 'Ancient Egyptian',
    id: 'ancient egyptian',
  },
  {
    name: 'Ancient Greek',
    id: 'ancient greek',
  },
  {
    name: 'Angika',
    id: 'angika',
  },
  {
    name: 'Ao Naga',
    id: 'ao naga',
  },
  {
    name: 'Arabic',
    id: 'arabic',
  },
  {
    name: 'Aragonese',
    id: 'aragonese',
  },
  {
    name: 'Aramaic',
    id: 'aramaic',
  },
  {
    name: 'Araona',
    id: 'araona',
  },
  {
    name: 'Arapaho',
    id: 'arapaho',
  },
  {
    name: 'Arawak',
    id: 'arawak',
  },
  {
    name: 'Armenian',
    id: 'armenian',
  },
  {
    name: 'Aromanian',
    id: 'aromanian',
  },
  {
    name: 'Arpitan',
    id: 'arpitan',
  },
  {
    name: 'Assamese',
    id: 'assamese',
  },
  {
    name: 'Asturian',
    id: 'asturian',
  },
  {
    name: 'Asu',
    id: 'asu',
  },
  {
    name: 'Atsam',
    id: 'atsam',
  },
  {
    name: 'Austrian German',
    id: 'austrian german',
  },
  {
    name: 'Avaric',
    id: 'avaric',
  },
  {
    name: 'Avestan',
    id: 'avestan',
  },
  {
    name: 'Awadhi',
    id: 'awadhi',
  },
  {
    name: 'Aymara',
    id: 'aymara',
  },
  {
    name: 'Azerbaijani',
    id: 'azerbaijani',
  },
  {
    name: 'Badaga',
    id: 'badaga',
  },
  {
    name: 'Bafia',
    id: 'bafia',
  },
  {
    name: 'Bafut',
    id: 'bafut',
  },
  {
    name: 'Bakhtiari',
    id: 'bakhtiari',
  },
  {
    name: 'Balinese',
    id: 'balinese',
  },
  {
    name: 'Baluchi',
    id: 'baluchi',
  },
  {
    name: 'Bambara',
    id: 'bambara',
  },
  {
    name: 'Bamun',
    id: 'bamun',
  },
  {
    name: 'Banjar',
    id: 'banjar',
  },
  {
    name: 'Basaa',
    id: 'basaa',
  },
  {
    name: 'Bashkir',
    id: 'bashkir',
  },
  {
    name: 'Basque',
    id: 'basque',
  },
  {
    name: 'Batak Toba',
    id: 'batak toba',
  },
  {
    name: 'Bavarian',
    id: 'bavarian',
  },
  {
    name: 'Beja',
    id: 'beja',
  },
  {
    name: 'Belarusian',
    id: 'belarusian',
  },
  {
    name: 'Bemba',
    id: 'bemba',
  },
  {
    name: 'Bena',
    id: 'bena',
  },
  {
    name: 'Bengali',
    id: 'bengali',
  },
  {
    name: 'Betawi',
    id: 'betawi',
  },
  {
    name: 'Bhojpuri',
    id: 'bhojpuri',
  },
  {
    name: 'Bikol',
    id: 'bikol',
  },
  {
    name: 'Bini',
    id: 'bini',
  },
  {
    name: 'Bishnupriya',
    id: 'bishnupriya',
  },
  {
    name: 'Bislama',
    id: 'bislama',
  },
  {
    name: 'Blin',
    id: 'blin',
  },
  {
    name: 'Blissymbols',
    id: 'blissymbols',
  },
  {
    name: 'Bodo',
    id: 'bodo',
  },
  {
    name: 'Bosnian',
    id: 'bosnian',
  },
  {
    name: 'Brahui',
    id: 'brahui',
  },
  {
    name: 'Braj',
    id: 'braj',
  },
  {
    name: 'Brazilian Portuguese',
    id: 'brazilian portuguese',
  },
  {
    name: 'Breton',
    id: 'breton',
  },
  {
    name: 'Buginese',
    id: 'buginese',
  },
  {
    name: 'Bulgarian',
    id: 'bulgarian',
  },
  {
    name: 'Bulu',
    id: 'bulu',
  },
  {
    name: 'Buriat',
    id: 'buriat',
  },
  {
    name: 'Burmese',
    id: 'burmese',
  },
  {
    name: 'Caddo',
    id: 'caddo',
  },
  {
    name: 'Cajun French',
    id: 'cajun french',
  },
  {
    name: 'Canadian French',
    id: 'canadian french',
  },
  {
    name: 'Cantonese',
    id: 'cantonese',
  },
  {
    name: 'Capiznon',
    id: 'capiznon',
  },
  {
    name: 'Carib',
    id: 'carib',
  },
  {
    name: 'Catalan',
    id: 'catalan',
  },
  {
    name: 'Cayuga',
    id: 'cayuga',
  },
  {
    name: 'Cebuano',
    id: 'cebuano',
  },
  {
    name: 'Central Atlas Tamazight',
    id: 'central atlas tamazight',
  },
  {
    name: 'Central Dusun',
    id: 'central dusun',
  },
  {
    name: 'Central Kurdish',
    id: 'central kurdish',
  },
  {
    name: 'Central Yupik',
    id: 'central yupik',
  },
  {
    name: 'Chadian Arabic',
    id: 'chadian arabic',
  },
  {
    name: 'Chagatai',
    id: 'chagatai',
  },
  {
    name: 'Chamorro',
    id: 'chamorro',
  },
  {
    name: 'Chechen',
    id: 'chechen',
  },
  {
    name: 'Cherokee',
    id: 'cherokee',
  },
  {
    name: 'Cheyenne',
    id: 'cheyenne',
  },
  {
    name: 'Chibcha',
    id: 'chibcha',
  },
  {
    name: 'Chiga',
    id: 'chiga',
  },
  {
    name: 'Chimborazo Highland Quichua',
    id: 'chimborazo highland quichua',
  },
  {
    name: 'Chinese',
    id: 'chinese',
  },
  {
    name: 'Chinook Jargon',
    id: 'chinook jargon',
  },
  {
    name: 'Chipewyan',
    id: 'chipewyan',
  },
  {
    name: 'Choctaw',
    id: 'choctaw',
  },
  {
    name: 'Church Slavic',
    id: 'church slavic',
  },
  {
    name: 'Chuukese',
    id: 'chuukese',
  },
  {
    name: 'Chuvash',
    id: 'chuvash',
  },
  {
    name: 'Classical Newari',
    id: 'classical newari',
  },
  {
    name: 'Classical Syriac',
    id: 'classical syriac',
  },
  {
    name: 'Colognian',
    id: 'colognian',
  },
  {
    name: 'Comorian',
    id: 'comorian',
  },
  {
    name: 'Congo Swahili',
    id: 'congo swahili',
  },
  {
    name: 'Coptic',
    id: 'coptic',
  },
  {
    name: 'Cornish',
    id: 'cornish',
  },
  {
    name: 'Corsican',
    id: 'corsican',
  },
  {
    name: 'Cree',
    id: 'cree',
  },
  {
    name: 'Creek',
    id: 'creek',
  },
  {
    name: 'Crimean Turkish',
    id: 'crimean turkish',
  },
  {
    name: 'Croatian',
    id: 'croatian',
  },
  {
    name: 'Czech',
    id: 'czech',
  },
  {
    name: 'Dakota',
    id: 'dakota',
  },
  {
    name: 'Danish',
    id: 'danish',
  },
  {
    name: 'Dargwa',
    id: 'dargwa',
  },
  {
    name: 'Dazaga',
    id: 'dazaga',
  },
  {
    name: 'Delaware',
    id: 'delaware',
  },
  {
    name: 'Dinka',
    id: 'dinka',
  },
  {
    name: 'Divehi',
    id: 'divehi',
  },
  {
    name: 'Dogri',
    id: 'dogri',
  },
  {
    name: 'Dogrib',
    id: 'dogrib',
  },
  {
    name: 'Duala',
    id: 'duala',
  },
  {
    name: 'Dutch',
    id: 'dutch',
  },
  {
    name: 'Dyula',
    id: 'dyula',
  },
  {
    name: 'Dzongkha',
    id: 'dzongkha',
  },
  {
    name: 'Eastern Frisian',
    id: 'eastern frisian',
  },
  {
    name: 'Efik',
    id: 'efik',
  },
  {
    name: 'Egyptian Arabic',
    id: 'egyptian arabic',
  },
  {
    name: 'Ekajuk',
    id: 'ekajuk',
  },
  {
    name: 'Elamite',
    id: 'elamite',
  },
  {
    name: 'Embu',
    id: 'embu',
  },
  {
    name: 'Emilian',
    id: 'emilian',
  },
  {
    name: 'English',
    id: 'english',
  },
  {
    name: 'Erzya',
    id: 'erzya',
  },
  {
    name: 'Esperanto',
    id: 'esperanto',
  },
  {
    name: 'Estonian',
    id: 'estonian',
  },
  {
    name: 'European Portuguese',
    id: 'european portuguese',
  },
  {
    name: 'European Spanish',
    id: 'european spanish',
  },
  {
    name: 'Ewe',
    id: 'ewe',
  },
  {
    name: 'Ewondo',
    id: 'ewondo',
  },
  {
    name: 'Extremaduran',
    id: 'extremaduran',
  },
  {
    name: 'Fang',
    id: 'fang',
  },
  {
    name: 'Fanti',
    id: 'fanti',
  },
  {
    name: 'Faroese',
    id: 'faroese',
  },
  {
    name: 'Fiji Hindi',
    id: 'fiji hindi',
  },
  {
    name: 'Fijian',
    id: 'fijian',
  },
  {
    name: 'Filipino',
    id: 'filipino',
  },
  {
    name: 'Finnish',
    id: 'finnish',
  },
  {
    name: 'Flemish',
    id: 'flemish',
  },
  {
    name: 'Fon',
    id: 'fon',
  },
  {
    name: 'Frafra',
    id: 'frafra',
  },
  {
    name: 'French',
    id: 'french',
  },
  {
    name: 'Friulian',
    id: 'friulian',
  },
  {
    name: 'Fulah',
    id: 'fulah',
  },
  {
    name: 'Ga',
    id: 'ga',
  },
  {
    name: 'Gagauz',
    id: 'gagauz',
  },
  {
    name: 'Galician',
    id: 'galician',
  },
  {
    name: 'Gan Chinese',
    id: 'gan chinese',
  },
  {
    name: 'Ganda',
    id: 'ganda',
  },
  {
    name: 'Gayo',
    id: 'gayo',
  },
  {
    name: 'Gbaya',
    id: 'gbaya',
  },
  {
    name: 'Geez',
    id: 'geez',
  },
  {
    name: 'Georgian',
    id: 'georgian',
  },
  {
    name: 'German',
    id: 'german',
  },
  {
    name: 'Gheg Albanian',
    id: 'gheg albanian',
  },
  {
    name: 'Ghomala',
    id: 'ghomala',
  },
  {
    name: 'Gilaki',
    id: 'gilaki',
  },
  {
    name: 'Gilbertese',
    id: 'gilbertese',
  },
  {
    name: 'Goan Konkani',
    id: 'goan konkani',
  },
  {
    name: 'Gondi',
    id: 'gondi',
  },
  {
    name: 'Gorontalo',
    id: 'gorontalo',
  },
  {
    name: 'Gothic',
    id: 'gothic',
  },
  {
    name: 'Grebo',
    id: 'grebo',
  },
  {
    name: 'Greek',
    id: 'greek',
  },
  {
    name: 'Guarani',
    id: 'guarani',
  },
  {
    name: 'Gujarati',
    id: 'gujarati',
  },
  {
    name: 'Gusii',
    id: 'gusii',
  },
  {
    name: 'Gwichʼin',
    id: 'gwichʼin',
  },
  {
    name: 'Haida',
    id: 'haida',
  },
  {
    name: 'Haitian',
    id: 'haitian',
  },
  {
    name: 'Hakka Chinese',
    id: 'hakka chinese',
  },
  {
    name: 'Hausa',
    id: 'hausa',
  },
  {
    name: 'Hawaiian',
    id: 'hawaiian',
  },
  {
    name: 'Hebrew',
    id: 'hebrew',
  },
  {
    name: 'Herero',
    id: 'herero',
  },
  {
    name: 'Hiligaynon',
    id: 'hiligaynon',
  },
  {
    name: 'Hindi',
    id: 'hindi',
  },
  {
    name: 'Hiri Motu',
    id: 'hiri motu',
  },
  {
    name: 'Hittite',
    id: 'hittite',
  },
  {
    name: 'Hmong',
    id: 'hmong',
  },
  {
    name: 'Hungarian',
    id: 'hungarian',
  },
  {
    name: 'Hupa',
    id: 'hupa',
  },
  {
    name: 'Iban',
    id: 'iban',
  },
  {
    name: 'Ibibio',
    id: 'ibibio',
  },
  {
    name: 'Icelandic',
    id: 'icelandic',
  },
  {
    name: 'Ido',
    id: 'ido',
  },
  {
    name: 'Igbo',
    id: 'igbo',
  },
  {
    name: 'Iloko',
    id: 'iloko',
  },
  {
    name: 'Inari Sami',
    id: 'inari sami',
  },
  {
    name: 'Indonesian',
    id: 'indonesian',
  },
  {
    name: 'Ingrian',
    id: 'ingrian',
  },
  {
    name: 'Ingush',
    id: 'ingush',
  },
  {
    name: 'Interlingua',
    id: 'interlingua',
  },
  {
    name: 'Interlingue',
    id: 'interlingue',
  },
  {
    name: 'Inuktitut',
    id: 'inuktitut',
  },
  {
    name: 'Inupiaq',
    id: 'inupiaq',
  },
  {
    name: 'Irish',
    id: 'irish',
  },
  {
    name: 'Italian',
    id: 'italian',
  },
  {
    name: 'Japanese',
    id: 'japanese',
  },
  {
    name: 'Javanese',
    id: 'javanese',
  },
  {
    name: 'Jju',
    id: 'jju',
  },
  {
    name: 'Jola-Fonyi',
    id: 'jola-fonyi',
  },
  {
    name: 'Judeo-Arabic',
    id: 'judeo-arabic',
  },
  {
    name: 'Judeo-Persian',
    id: 'judeo-persian',
  },
  {
    name: 'Jutish',
    id: 'jutish',
  },
  {
    name: 'Kabardian',
    id: 'kabardian',
  },
  {
    name: 'Kabuverdianu',
    id: 'kabuverdianu',
  },
  {
    name: 'Kabyle',
    id: 'kabyle',
  },
  {
    name: 'Kachin',
    id: 'kachin',
  },
  {
    name: 'Kaingang',
    id: 'kaingang',
  },
  {
    name: 'Kako',
    id: 'kako',
  },
  {
    name: 'Kalaallisut',
    id: 'kalaallisut',
  },
  {
    name: 'Kalenjin',
    id: 'kalenjin',
  },
  {
    name: 'Kalmyk',
    id: 'kalmyk',
  },
  {
    name: 'Kamba',
    id: 'kamba',
  },
  {
    name: 'Kanembu',
    id: 'kanembu',
  },
  {
    name: 'Kannada',
    id: 'kannada',
  },
  {
    name: 'Kanuri',
    id: 'kanuri',
  },
  {
    name: 'Kara-Kalpak',
    id: 'kara-kalpak',
  },
  {
    name: 'Karachay-Balkar',
    id: 'karachay-balkar',
  },
  {
    name: 'Karelian',
    id: 'karelian',
  },
  {
    name: 'Kashmiri',
    id: 'kashmiri',
  },
  {
    name: 'Kashubian',
    id: 'kashubian',
  },
  {
    name: 'Kawi',
    id: 'kawi',
  },
  {
    name: 'Kazakh',
    id: 'kazakh',
  },
  {
    name: 'Kenyang',
    id: 'kenyang',
  },
  {
    name: 'Khasi',
    id: 'khasi',
  },
  {
    name: 'Khmer',
    id: 'khmer',
  },
  {
    name: 'Khotanese',
    id: 'khotanese',
  },
  {
    name: 'Khowar',
    id: 'khowar',
  },
  {
    name: 'Kikuyu',
    id: 'kikuyu',
  },
  {
    name: 'Kimbundu',
    id: 'kimbundu',
  },
  {
    name: 'Kinaray-a',
    id: 'kinaray-a',
  },
  {
    name: 'Kinyarwanda',
    id: 'kinyarwanda',
  },
  {
    name: 'Kirmanjki',
    id: 'kirmanjki',
  },
  {
    name: 'Klingon',
    id: 'klingon',
  },
  {
    name: 'Kom',
    id: 'kom',
  },
  {
    name: 'Komi',
    id: 'komi',
  },
  {
    name: 'Komi-Permyak',
    id: 'komi-permyak',
  },
  {
    name: 'Kongo',
    id: 'kongo',
  },
  {
    name: 'Konkani',
    id: 'konkani',
  },
  {
    name: 'Korean',
    id: 'korean',
  },
  {
    name: 'Koro',
    id: 'koro',
  },
  {
    name: 'Kosraean',
    id: 'kosraean',
  },
  {
    name: 'Kotava',
    id: 'kotava',
  },
  {
    name: 'Koyra Chiini',
    id: 'koyra chiini',
  },
  {
    name: 'Koyraboro Senni',
    id: 'koyraboro senni',
  },
  {
    name: 'Kpelle',
    id: 'kpelle',
  },
  {
    name: 'Krio',
    id: 'krio',
  },
  {
    name: 'Kuanyama',
    id: 'kuanyama',
  },
  {
    name: 'Kumyk',
    id: 'kumyk',
  },
  {
    name: 'Kurdish',
    id: 'kurdish',
  },
  {
    name: 'Kurukh',
    id: 'kurukh',
  },
  {
    name: 'Kutenai',
    id: 'kutenai',
  },
  {
    name: 'Kwasio',
    id: 'kwasio',
  },
  {
    name: 'Kyrgyz',
    id: 'kyrgyz',
  },
  {
    name: 'Kʼicheʼ',
    id: 'kʼicheʼ',
  },
  {
    name: 'Ladino',
    id: 'ladino',
  },
  {
    name: 'Lahnda',
    id: 'lahnda',
  },
  {
    name: 'Lakota',
    id: 'lakota',
  },
  {
    name: 'Lamba',
    id: 'lamba',
  },
  {
    name: 'Langi',
    id: 'langi',
  },
  {
    name: 'Lao',
    id: 'lao',
  },
  {
    name: 'Latgalian',
    id: 'latgalian',
  },
  {
    name: 'Latin',
    id: 'latin',
  },
  {
    name: 'Latin American Spanish',
    id: 'latin american spanish',
  },
  {
    name: 'Latvian',
    id: 'latvian',
  },
  {
    name: 'Laz',
    id: 'laz',
  },
  {
    name: 'Lezghian',
    id: 'lezghian',
  },
  {
    name: 'Ligurian',
    id: 'ligurian',
  },
  {
    name: 'Limburgish',
    id: 'limburgish',
  },
  {
    name: 'Lingala',
    id: 'lingala',
  },
  {
    name: 'Lingua Franca Nova',
    id: 'lingua franca nova',
  },
  {
    name: 'Literary Chinese',
    id: 'literary chinese',
  },
  {
    name: 'Lithuanian',
    id: 'lithuanian',
  },
  {
    name: 'Livonian',
    id: 'livonian',
  },
  {
    name: 'Lojban',
    id: 'lojban',
  },
  {
    name: 'Lombard',
    id: 'lombard',
  },
  {
    name: 'Low German',
    id: 'low german',
  },
  {
    name: 'Lower Silesian',
    id: 'lower silesian',
  },
  {
    name: 'Lower Sorbian',
    id: 'lower sorbian',
  },
  {
    name: 'Lozi',
    id: 'lozi',
  },
  {
    name: 'Luba-Katanga',
    id: 'luba-katanga',
  },
  {
    name: 'Luba-Lulua',
    id: 'luba-lulua',
  },
  {
    name: 'Luiseno',
    id: 'luiseno',
  },
  {
    name: 'Lule Sami',
    id: 'lule sami',
  },
  {
    name: 'Lunda',
    id: 'lunda',
  },
  {
    name: 'Luo',
    id: 'luo',
  },
  {
    name: 'Luxembourgish',
    id: 'luxembourgish',
  },
  {
    name: 'Luyia',
    id: 'luyia',
  },
  {
    name: 'Maba',
    id: 'maba',
  },
  {
    name: 'Macedonian',
    id: 'macedonian',
  },
  {
    name: 'Machame',
    id: 'machame',
  },
  {
    name: 'Madurese',
    id: 'madurese',
  },
  {
    name: 'Mafa',
    id: 'mafa',
  },
  {
    name: 'Magahi',
    id: 'magahi',
  },
  {
    name: 'Main-Franconian',
    id: 'main-franconian',
  },
  {
    name: 'Maithili',
    id: 'maithili',
  },
  {
    name: 'Makasar',
    id: 'makasar',
  },
  {
    name: 'Makhuwa-Meetto',
    id: 'makhuwa-meetto',
  },
  {
    name: 'Makonde',
    id: 'makonde',
  },
  {
    name: 'Malagasy',
    id: 'malagasy',
  },
  {
    name: 'Malay',
    id: 'malay',
  },
  {
    name: 'Malayalam',
    id: 'malayalam',
  },
  {
    name: 'Maltese',
    id: 'maltese',
  },
  {
    name: 'Manchu',
    id: 'manchu',
  },
  {
    name: 'Mandar',
    id: 'mandar',
  },
  {
    name: 'Mandingo',
    id: 'mandingo',
  },
  {
    name: 'Manipuri',
    id: 'manipuri',
  },
  {
    name: 'Manx',
    id: 'manx',
  },
  {
    name: 'Maori',
    id: 'maori',
  },
  {
    name: 'Mapuche',
    id: 'mapuche',
  },
  {
    name: 'Marathi',
    id: 'marathi',
  },
  {
    name: 'Mari',
    id: 'mari',
  },
  {
    name: 'Marshallese',
    id: 'marshallese',
  },
  {
    name: 'Marwari',
    id: 'marwari',
  },
  {
    name: 'Masai',
    id: 'masai',
  },
  {
    name: 'Mazanderani',
    id: 'mazanderani',
  },
  {
    name: 'Medumba',
    id: 'medumba',
  },
  {
    name: 'Mende',
    id: 'mende',
  },
  {
    name: 'Mentawai',
    id: 'mentawai',
  },
  {
    name: 'Meru',
    id: 'meru',
  },
  {
    name: 'Metaʼ',
    id: 'metaʼ',
  },
  {
    name: 'Mexican Spanish',
    id: 'mexican spanish',
  },
  {
    name: 'Micmac',
    id: 'micmac',
  },
  {
    name: 'Middle Dutch',
    id: 'middle dutch',
  },
  {
    name: 'Middle French',
    id: 'middle french',
  },
  {
    name: 'Middle High German',
    id: 'middle high german',
  },
  {
    name: 'Middle Irish',
    id: 'middle irish',
  },
  {
    name: 'Min Nan Chinese',
    id: 'min nan chinese',
  },
  {
    name: 'Minangkabau',
    id: 'minangkabau',
  },
  {
    name: 'Mingrelian',
    id: 'mingrelian',
  },
  {
    name: 'Mirandese',
    id: 'mirandese',
  },
  {
    name: 'Mizo',
    id: 'mizo',
  },
  {
    name: 'Modern Standard Arabic',
    id: 'modern standard arabic',
  },
  {
    name: 'Mohawk',
    id: 'mohawk',
  },
  {
    name: 'Moksha',
    id: 'moksha',
  },
  {
    name: 'Moldavian',
    id: 'moldavian',
  },
  {
    name: 'Mongo',
    id: 'mongo',
  },
  {
    name: 'Mongolian',
    id: 'mongolian',
  },
  {
    name: 'Morisyen',
    id: 'morisyen',
  },
  {
    name: 'Moroccan Arabic',
    id: 'moroccan arabic',
  },
  {
    name: 'Mossi',
    id: 'mossi',
  },
  {
    name: 'Multiple Languages',
    id: 'multiple languages',
  },
  {
    name: 'Mundang',
    id: 'mundang',
  },
  {
    name: 'Muslim Tat',
    id: 'muslim tat',
  },
  {
    name: 'Myene',
    id: 'myene',
  },
  {
    name: 'Nama',
    id: 'nama',
  },
  {
    name: 'Nauru',
    id: 'nauru',
  },
  {
    name: 'Navajo',
    id: 'navajo',
  },
  {
    name: 'Ndonga',
    id: 'ndonga',
  },
  {
    name: 'Neapolitan',
    id: 'neapolitan',
  },
  {
    name: 'Nepali',
    id: 'nepali',
  },
  {
    name: 'Newari',
    id: 'newari',
  },
  {
    name: 'Ngambay',
    id: 'ngambay',
  },
  {
    name: 'Ngiemboon',
    id: 'ngiemboon',
  },
  {
    name: 'Ngomba',
    id: 'ngomba',
  },
  {
    name: 'Nheengatu',
    id: 'nheengatu',
  },
  {
    name: 'Nias',
    id: 'nias',
  },
  {
    name: 'Niuean',
    id: 'niuean',
  },
  {
    name: 'No linguistic content',
    id: 'no linguistic content',
  },
  {
    name: 'Nogai',
    id: 'nogai',
  },
  {
    name: 'North Ndebele',
    id: 'north ndebele',
  },
  {
    name: 'Northern Frisian',
    id: 'northern frisian',
  },
  {
    name: 'Northern Sami',
    id: 'northern sami',
  },
  {
    name: 'Northern Sotho',
    id: 'northern sotho',
  },
  {
    name: 'Norwegian',
    id: 'norwegian',
  },
  {
    name: 'Norwegian Bokmål',
    id: 'norwegian bokmål',
  },
  {
    name: 'Norwegian Nynorsk',
    id: 'norwegian nynorsk',
  },
  {
    name: 'Novial',
    id: 'novial',
  },
  {
    name: 'Nuer',
    id: 'nuer',
  },
  {
    name: 'Nyamwezi',
    id: 'nyamwezi',
  },
  {
    name: 'Nyanja',
    id: 'nyanja',
  },
  {
    name: 'Nyankole',
    id: 'nyankole',
  },
  {
    name: 'Nyasa Tonga',
    id: 'nyasa tonga',
  },
  {
    name: 'Nyoro',
    id: 'nyoro',
  },
  {
    name: 'Nzima',
    id: 'nzima',
  },
  {
    name: 'NʼKo',
    id: 'nʼko',
  },
  {
    name: 'Occitan',
    id: 'occitan',
  },
  {
    name: 'Ojibwa',
    id: 'ojibwa',
  },
  {
    name: 'Old French',
    id: 'old french',
  },
  {
    name: 'Old High German',
    id: 'old high german',
  },
  {
    name: 'Old Irish',
    id: 'old irish',
  },
  {
    name: 'Old Norse',
    id: 'old norse',
  },
  {
    name: 'Old Persian',
    id: 'old persian',
  },
  {
    name: 'Old Provençal',
    id: 'old provençal',
  },
  {
    name: 'Oriya',
    id: 'oriya',
  },
  {
    name: 'Oromo',
    id: 'oromo',
  },
  {
    name: 'Osage',
    id: 'osage',
  },
  {
    name: 'Ossetic',
    id: 'ossetic',
  },
  {
    name: 'Ottoman Turkish',
    id: 'ottoman turkish',
  },
  {
    name: 'Pahlavi',
    id: 'pahlavi',
  },
  {
    name: 'Palatine German',
    id: 'palatine german',
  },
  {
    name: 'Palauan',
    id: 'palauan',
  },
  {
    name: 'Pali',
    id: 'pali',
  },
  {
    name: 'Pampanga',
    id: 'pampanga',
  },
  {
    name: 'Pangasinan',
    id: 'pangasinan',
  },
  {
    name: 'Papiamento',
    id: 'papiamento',
  },
  {
    name: 'Pashto',
    id: 'pashto',
  },
  {
    name: 'Pennsylvania German',
    id: 'pennsylvania german',
  },
  {
    name: 'Persian',
    id: 'persian',
  },
  {
    name: 'Phoenician',
    id: 'phoenician',
  },
  {
    name: 'Picard',
    id: 'picard',
  },
  {
    name: 'Piedmontese',
    id: 'piedmontese',
  },
  {
    name: 'Plautdietsch',
    id: 'plautdietsch',
  },
  {
    name: 'Pohnpeian',
    id: 'pohnpeian',
  },
  {
    name: 'Polish',
    id: 'polish',
  },
  {
    name: 'Pontic',
    id: 'pontic',
  },
  {
    name: 'Portuguese',
    id: 'portuguese',
  },
  {
    name: 'Prussian',
    id: 'prussian',
  },
  {
    name: 'Punjabi',
    id: 'punjabi',
  },
  {
    name: 'Quechua',
    id: 'quechua',
  },
  {
    name: 'Rajasthani',
    id: 'rajasthani',
  },
  {
    name: 'Rapanui',
    id: 'rapanui',
  },
  {
    name: 'Rarotongan',
    id: 'rarotongan',
  },
  {
    name: 'Riffian',
    id: 'riffian',
  },
  {
    name: 'Romagnol',
    id: 'romagnol',
  },
  {
    name: 'Romanian',
    id: 'romanian',
  },
  {
    name: 'Romansh',
    id: 'romansh',
  },
  {
    name: 'Romany',
    id: 'romany',
  },
  {
    name: 'Rombo',
    id: 'rombo',
  },
  {
    name: 'Root',
    id: 'root',
  },
  {
    name: 'Rotuman',
    id: 'rotuman',
  },
  {
    name: 'Roviana',
    id: 'roviana',
  },
  {
    name: 'Rundi',
    id: 'rundi',
  },
  {
    name: 'Russian',
    id: 'russian',
  },
  {
    name: 'Rusyn',
    id: 'rusyn',
  },
  {
    name: 'Rwa',
    id: 'rwa',
  },
  {
    name: 'Saho',
    id: 'saho',
  },
  {
    name: 'Sakha',
    id: 'sakha',
  },
  {
    name: 'Samaritan Aramaic',
    id: 'samaritan aramaic',
  },
  {
    name: 'Samburu',
    id: 'samburu',
  },
  {
    name: 'Samoan',
    id: 'samoan',
  },
  {
    name: 'Samogitian',
    id: 'samogitian',
  },
  {
    name: 'Sandawe',
    id: 'sandawe',
  },
  {
    name: 'Sango',
    id: 'sango',
  },
  {
    name: 'Sangu',
    id: 'sangu',
  },
  {
    name: 'Sanskrit',
    id: 'sanskrit',
  },
  {
    name: 'Santali',
    id: 'santali',
  },
  {
    name: 'Sardinian',
    id: 'sardinian',
  },
  {
    name: 'Sasak',
    id: 'sasak',
  },
  {
    name: 'Sassarese Sardinian',
    id: 'sassarese sardinian',
  },
  {
    name: 'Saterland Frisian',
    id: 'saterland frisian',
  },
  {
    name: 'Saurashtra',
    id: 'saurashtra',
  },
  {
    name: 'Scots',
    id: 'scots',
  },
  {
    name: 'Scottish Gaelic',
    id: 'scottish gaelic',
  },
  {
    name: 'Selayar',
    id: 'selayar',
  },
  {
    name: 'Selkup',
    id: 'selkup',
  },
  {
    name: 'Sena',
    id: 'sena',
  },
  {
    name: 'Seneca',
    id: 'seneca',
  },
  {
    name: 'Serbian',
    id: 'serbian',
  },
  {
    name: 'Serbo-Croatian',
    id: 'serbo-croatian',
  },
  {
    name: 'Serer',
    id: 'serer',
  },
  {
    name: 'Seri',
    id: 'seri',
  },
  {
    name: 'Shambala',
    id: 'shambala',
  },
  {
    name: 'Shan',
    id: 'shan',
  },
  {
    name: 'Shona',
    id: 'shona',
  },
  {
    name: 'Sichuan Yi',
    id: 'sichuan yi',
  },
  {
    name: 'Sicilian',
    id: 'sicilian',
  },
  {
    name: 'Sidamo',
    id: 'sidamo',
  },
  {
    name: 'Siksika',
    id: 'siksika',
  },
  {
    name: 'Silesian',
    id: 'silesian',
  },
  {
    name: 'Simplified Chinese',
    id: 'simplified chinese',
  },
  {
    name: 'Sindhi',
    id: 'sindhi',
  },
  {
    name: 'Sinhala',
    id: 'sinhala',
  },
  {
    name: 'Skolt Sami',
    id: 'skolt sami',
  },
  {
    name: 'Slave',
    id: 'slave',
  },
  {
    name: 'Slovak',
    id: 'slovak',
  },
  {
    name: 'Slovenian',
    id: 'slovenian',
  },
  {
    name: 'Soga',
    id: 'soga',
  },
  {
    name: 'Sogdien',
    id: 'sogdien',
  },
  {
    name: 'Somali',
    id: 'somali',
  },
  {
    name: 'Soninke',
    id: 'soninke',
  },
  {
    name: 'South Azerbaijani',
    id: 'south azerbaijani',
  },
  {
    name: 'South Ndebele',
    id: 'south ndebele',
  },
  {
    name: 'Southern Altai',
    id: 'southern altai',
  },
  {
    name: 'Southern Sami',
    id: 'southern sami',
  },
  {
    name: 'Southern Sotho',
    id: 'southern sotho',
  },
  {
    name: 'Spanish',
    id: 'spanish',
  },
  {
    name: 'Sranan Tongo',
    id: 'sranan tongo',
  },
  {
    name: 'Standard Moroccan Tamazight',
    id: 'standard moroccan tamazight',
  },
  {
    name: 'Sukuma',
    id: 'sukuma',
  },
  {
    name: 'Sumerian',
    id: 'sumerian',
  },
  {
    name: 'Sundanese',
    id: 'sundanese',
  },
  {
    name: 'Susu',
    id: 'susu',
  },
  {
    name: 'Swahili',
    id: 'swahili',
  },
  {
    name: 'Swati',
    id: 'swati',
  },
  {
    name: 'Swedish',
    id: 'swedish',
  },
  {
    name: 'Swiss French',
    id: 'swiss french',
  },
  {
    name: 'Swiss German',
    id: 'swiss german',
  },
  {
    name: 'Swiss High German',
    id: 'swiss high german',
  },
  {
    name: 'Syriac',
    id: 'syriac',
  },
  {
    name: 'Tachelhit',
    id: 'tachelhit',
  },
  {
    name: 'Tagalog',
    id: 'tagalog',
  },
  {
    name: 'Tahitian',
    id: 'tahitian',
  },
  {
    name: 'Taita',
    id: 'taita',
  },
  {
    name: 'Tajik',
    id: 'tajik',
  },
  {
    name: 'Talysh',
    id: 'talysh',
  },
  {
    name: 'Tamashek',
    id: 'tamashek',
  },
  {
    name: 'Tamil',
    id: 'tamil',
  },
  {
    name: 'Taroko',
    id: 'taroko',
  },
  {
    name: 'Tasawaq',
    id: 'tasawaq',
  },
  {
    name: 'Tatar',
    id: 'tatar',
  },
  {
    name: 'Telugu',
    id: 'telugu',
  },
  {
    name: 'Tereno',
    id: 'tereno',
  },
  {
    name: 'Teso',
    id: 'teso',
  },
  {
    name: 'Tetum',
    id: 'tetum',
  },
  {
    name: 'Thai',
    id: 'thai',
  },
  {
    name: 'Tibetan',
    id: 'tibetan',
  },
  {
    name: 'Tigre',
    id: 'tigre',
  },
  {
    name: 'Tigrinya',
    id: 'tigrinya',
  },
  {
    name: 'Timne',
    id: 'timne',
  },
  {
    name: 'Tiv',
    id: 'tiv',
  },
  {
    name: 'Tlingit',
    id: 'tlingit',
  },
  {
    name: 'Tok Pisin',
    id: 'tok pisin',
  },
  {
    name: 'Tokelau',
    id: 'tokelau',
  },
  {
    name: 'Tongan',
    id: 'tongan',
  },
  {
    name: 'Tornedalen Finnish',
    id: 'tornedalen finnish',
  },
  {
    name: 'Traditional Chinese',
    id: 'traditional chinese',
  },
  {
    name: 'Tsakhur',
    id: 'tsakhur',
  },
  {
    name: 'Tsakonian',
    id: 'tsakonian',
  },
  {
    name: 'Tsimshian',
    id: 'tsimshian',
  },
  {
    name: 'Tsonga',
    id: 'tsonga',
  },
  {
    name: 'Tswana',
    id: 'tswana',
  },
  {
    name: 'Tulu',
    id: 'tulu',
  },
  {
    name: 'Tumbuka',
    id: 'tumbuka',
  },
  {
    name: 'Tunisian Arabic',
    id: 'tunisian arabic',
  },
  {
    name: 'Turkish',
    id: 'turkish',
  },
  {
    name: 'Turkmen',
    id: 'turkmen',
  },
  {
    name: 'Turoyo',
    id: 'turoyo',
  },
  {
    name: 'Tuvalu',
    id: 'tuvalu',
  },
  {
    name: 'Tuvinian',
    id: 'tuvinian',
  },
  {
    name: 'Twi',
    id: 'twi',
  },
  {
    name: 'Tyap',
    id: 'tyap',
  },
  {
    name: 'Udmurt',
    id: 'udmurt',
  },
  {
    name: 'Ugaritic',
    id: 'ugaritic',
  },
  {
    name: 'Ukrainian',
    id: 'ukrainian',
  },
  {
    name: 'Umbundu',
    id: 'umbundu',
  },
  {
    name: 'Unknown Language',
    id: 'unknown language',
  },
  {
    name: 'Upper Sorbian',
    id: 'upper sorbian',
  },
  {
    name: 'Urdu',
    id: 'urdu',
  },
  {
    name: 'Uyghur',
    id: 'uyghur',
  },
  {
    name: 'Uzbek',
    id: 'uzbek',
  },
  {
    name: 'Vai',
    id: 'vai',
  },
  {
    name: 'Venda',
    id: 'venda',
  },
  {
    name: 'Venetian',
    id: 'venetian',
  },
  {
    name: 'Veps',
    id: 'veps',
  },
  {
    name: 'Vietnamese',
    id: 'vietnamese',
  },
  {
    name: 'Volapük',
    id: 'volapük',
  },
  {
    name: 'Võro',
    id: 'võro',
  },
  {
    name: 'Votic',
    id: 'votic',
  },
  {
    name: 'Vunjo',
    id: 'vunjo',
  },
  {
    name: 'Walloon',
    id: 'walloon',
  },
  {
    name: 'Walser',
    id: 'walser',
  },
  {
    name: 'Waray',
    id: 'waray',
  },
  {
    name: 'Warlpiri',
    id: 'warlpiri',
  },
  {
    name: 'Washo',
    id: 'washo',
  },
  {
    name: 'Wayuu',
    id: 'wayuu',
  },
  {
    name: 'Welsh',
    id: 'welsh',
  },
  {
    name: 'West Flemish',
    id: 'west flemish',
  },
  {
    name: 'Western Frisian',
    id: 'western frisian',
  },
  {
    name: 'Western Mari',
    id: 'western mari',
  },
  {
    name: 'Wolaytta',
    id: 'wolaytta',
  },
  {
    name: 'Wolof',
    id: 'wolof',
  },
  {
    name: 'Wu Chinese',
    id: 'wu chinese',
  },
  {
    name: 'Xhosa',
    id: 'xhosa',
  },
  {
    name: 'Xiang Chinese',
    id: 'xiang chinese',
  },
  {
    name: 'Yangben',
    id: 'yangben',
  },
  {
    name: 'Yao',
    id: 'yao',
  },
  {
    name: 'Yapese',
    id: 'yapese',
  },
  {
    name: 'Yemba',
    id: 'yemba',
  },
  {
    name: 'Yiddish',
    id: 'yiddish',
  },
  {
    name: 'Yoruba',
    id: 'yoruba',
  },
  {
    name: 'Zapotec',
    id: 'zapotec',
  },
  {
    name: 'Zarma',
    id: 'zarma',
  },
  {
    name: 'Zaza',
    id: 'zaza',
  },
  {
    name: 'Zeelandic',
    id: 'zeelandic',
  },
  {
    name: 'Zenaga',
    id: 'zenaga',
  },
  {
    name: 'Zhuang',
    id: 'zhuang',
  },
  {
    name: 'Zoroastrian Dari',
    id: 'zoroastrian dari',
  },
  {
    name: 'Zulu',
    id: 'zulu',
  },
  {
    name: 'Zuni',
    id: 'zuni',
  },
];

const novelsTopics = [
  {
    language: 'hindi',
    topic_id: '1c42d6fed3c2342d391be5d92bcd22bb16b84b73',
    type: 'Series',
    sub_topics: [
      {
        topic_id: 'ed41467b4c0870a67cf8a8849b9ef73885599c63',
        topic_name: 'Romance',
      },
      {
        topic_id: 'c85fd5b41284966384d9b428212df112df696302',
        topic_name: 'Suspense and Thriller',
      },
    ],
  },
  {
    language: 'english',
    topic_id: '5fc60105f469b212c7d3f8f2b8bf0f91d38d00fd',
    type: 'Series',
    sub_topics: [
      {
        topic_id: '7ae626f7d428b85bfbe09b0cca10b9f391c16eea',
        topic_name: 'Romance',
      },
      {
        topic_id: 'f52b29256db53d92b2c1f309d7d76c1260383b87',
        topic_name: 'Suspense and Thriller',
      },
    ],
  },
];

const tags = [
  {
    name: 'Ambitious Girl',
    id: '4f9065d1ad65e1fc28f0cc7623461165e1662367',
  },
  {
    name: 'Arranged Marriage',
    id: '73cc1e7c06abe2e74932cf91dfbd12b44db6c54b',
  },
  {
    name: 'Arrogant Guy',
    id: '160fe4c0c097673f89a60e145a639bfce3c6f678',
  },
  {
    name: 'Aspiring Actress',
    id: 'f4a9a27a27ad4ec9e060f3c6cc78aeeed9ea1bfb',
  },
  {
    name: 'Aspiring Girl',
    id: 'cd3b51a1226e36f28fe45607dabc04675922e221',
  },
  {
    name: 'Betrayal',
    id: 'af6510ca5403984995b99376d42282d081641fd7',
  },
  {
    name: 'Boy Hates Girl',
    id: '73b898e41e5999c2acbada2913bdf443fd5a551d',
  },
  {
    name: 'Celebrity',
    id: '243d9cab5b7db34b04ca0acf5604c5facdec1e2e',
  },
  {
    name: 'Childhood Friends',
    id: '8a53c7c6f47bd37b71ccdc3f8cfeddff658c81f8',
  },
  {
    name: 'Childhood Love',
    id: '01331455bfc7f0aa9aa8d27d5c0f7e7b23ec0772',
  },
  {
    name: 'College Life',
    id: '3132003ddb8cf903800d59469d58dfe762c65431',
  },
  {
    name: 'College Romance',
    id: '52e558fc07f21e8db8586a485506cada4440b53b',
  },
  {
    name: 'Conspiracy',
    id: '92b1acc6118e38f7c303c7f143eb16635ccdb1fb',
  },
  {
    name: 'Contract Marriage',
    id: 'db81ef170d39f6ed67567b08ee2efd2022507b43',
  },
  {
    name: 'Corporate Love',
    id: '09d8c291e963dfab680d1d0de12a450a79e27c5a',
  },
  {
    name: 'Cute Child',
    id: '866d7a09accdc0951f5a3417873a0bd7ac4bbddc',
  },
  {
    name: 'Dark Past',
    id: 'a20d4436077854f12871437dedae4befb207b7c8',
  },
  {
    name: 'Divorce',
    id: 'b348022534dc960b6e9ea5b0864a8b8eb68c261a',
  },
  {
    name: 'Extra-Marrital Affair',
    id: 'ef1b0fc6af48d4d202254a461e7421c6f907b658',
  },
  {
    name: 'Falling in Love Again',
    id: '41e80a65ce5249697881a9ac3c1512a74ded8bce',
  },
  {
    name: "Father's Love",
    id: '49421923cb7cd866856b0cd7482f2717f3b780fa',
  },
  {
    name: 'Forced Marriage',
    id: 'c36ebf56be4a65acef89ebfdc47c3f2838788e49',
  },
  {
    name: 'Husband-Wife',
    id: '486e7658507b662a2d5fc60cb6ccb48c5faacb80',
  },
  {
    name: 'Live-in Relationship',
    id: 'c3634ba14b008e2e99ec3b90a8609868a9665cf7',
  },
  {
    name: 'Love Marriage',
    id: 'f1fe4f21e99e2fb1c2c8347e8a43980b25830265',
  },
  {
    name: 'Love Triangle',
    id: '806aa53923cebed553129a271096a7f123024629',
  },
  {
    name: 'Love vs Friendship',
    id: '2c1a1693129b788465a17afbad03c00561527b74',
  },
  {
    name: 'Memory Loss',
    id: '4599b4e18e618753b98db6bd632ceacf4e8bdc23',
  },
  {
    name: 'Modern Romance',
    id: 'e8fc874fb95d124a81676142c0cb21f2fd82b386',
  },
  {
    name: "Mother's Love",
    id: '776c9dd75792581d9bd88144b007e92ead3b2418',
  },
  {
    name: 'One-sided Love',
    id: 'aa44c9d5841a6a61efdf7e14b99defbec534a997',
  },
  {
    name: 'Re-birth',
    id: '1b2b18323dbff4f511dd8a87913d13ed5ca223fd',
  },
  {
    name: 'Rebel',
    id: '2efabfd7b6fd62f6ad1463640c11712cffe00400',
  },
  {
    name: 'Revenge',
    id: '91daf1f87e612fd018c2fd12cf14277a2ff153c2',
  },
  {
    name: 'Rich Guy',
    id: 'e46c8b40f2e5d9985a00fc62e8506b0bac99c07a',
  },
  {
    name: 'Road Trip',
    id: 'fa7a8ea480cb25a247d248a179b5fb032a25fc63',
  },
  {
    name: 'Secret Love',
    id: '156d542ce098da352ae7bdbe17e4e33996fff95c',
  },
  {
    name: 'Single Parent',
    id: 'df7d595ec8af33cfcdcbea426c565cd81c0df7ce',
  },
  {
    name: 'Small Town',
    id: 'edd24a5ef0fa7969387024ade1f86d8c54e0b565',
  },
  {
    name: 'Strong Women',
    id: '89ec76807c920ff8c235eeaec0b96549749ac058',
  },
  {
    name: 'Teenage Love',
    id: '47f4314a1e55d6bd9f449d515c5e0e29cda908bc',
  },
  {
    name: 'Women Power',
    id: '8c5a2d561c0fed5cbc9bb7377f9b6d0c493edef1',
  },
];

const audioSeries = [
  { name: 'Romantic Audio-Series', slug: 'romanticAudioSeries' },
  { name: 'Horror Audio-Series', slug: 'horrorAudioSeries' },
  {
    name: 'Suspense and Thriller Audio Series',
    slug: 'suspenceAndThrillerAudioSeries',
  },
  { name: 'Family Drama', slug: 'familyDrama' },
  { name: 'Historical Drama', slug: 'historicalDrama' },
];

const countryList = [
  { name: 'Albania', code: 'AL' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas (the)', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia (Plurinational State of)', code: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory (the)', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cabo Verde', code: 'CV' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cayman Islands (the)', code: 'KY' },
  { name: 'Central African Republic (the)', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands (the)', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros (the)', code: 'KM' },
  { name: 'Congo (the Democratic Republic of the)', code: 'CD' },
  { name: 'Congo (the)', code: 'CG' },
  { name: 'Cook Islands (the)', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czechia', code: 'CZ' },
  { name: "Côte d'Ivoire", code: 'CI' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic (the)', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Eswatini', code: 'SZ' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (the) [Malvinas]', code: 'FK' },
  { name: 'Faroe Islands (the)', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories (the)', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia (the)', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and McDonald Islands', code: 'HM' },
  { name: 'Holy See (the)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran (Islamic Republic of)', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea (the Democratic People's Republic of)", code: 'KP' },
  { name: 'Korea (the Republic of)', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People's Democratic Republic (the)", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands (the)', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia (Federated States of)', code: 'FM' },
  { name: 'Moldova (the Republic of)', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands (the)', code: 'NL' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger (the)', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands (the)', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestine, State of', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Republic of North Macedonia', code: 'MK' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Saint Barthélemy', code: 'BL' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Martin (French part)', code: 'MF' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten (Dutch part)', code: 'SX' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan (Province of China)', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', code: 'GB' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'United States of America (USA)', code: 'US' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela (Bolivarian Republic of)', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands (British)', code: 'VG' },
  { name: 'Virgin Islands (U.S.)', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

const commonFAQ = [
  {
    question: 'How to download mobile applications?',
    answer:
      "Android- <a href='https://play.google.com/store/apps/details?id=com.pocketfm.novel'>here</a> , iPhone- <a href='https://play.google.com/store/apps/details?id=com.pocketfm.novel'>here</a>",
  },
  {
    question: 'How do I login? How to use the app?',
    answer:
      "Please use this for login - <a href='https://writer.pocketnovel.com/login'>here</a> and follow these steps - tutorial ",
    youtubeLink: 'Tu9XoKAP918',
  },
  {
    question: 'Didn’t get the OTP or Face issue during logging in?',
    answer:
      "Please try to get OTP on WhatsApp. If the issue still persists then mail us on <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a> along with your login info like phone number you are trying to use.",
  },
  {
    question: 'How to register as a writer? Any tips to create a novel?',
    answer: 'Please follow these steps ',
    youtubeLink: 'Hs8Jq8leaoU',
  },
  {
    question: 'How to publish a chapter?',
    answer:
      "If you want to add chapters to a novel on our platform, you have to login to our <a href='https://writer.pocketnovel.com/'>writers@pocketfm.com</a> or our mobile app Pocket Novel Reader. This link will help you to understand.Watch here",
  },
  {
    question: 'What are the terms & conditions for writing?',
    answer:
      "Every chapter has a minimum limit of 1,000 words and a maximum of 5,000 words which need to be uploaded on a daily basis.Only original fiction stories are allowed. We disable plagiarism and sexually explicit books.For detailed information, please write to us at <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a>",
  },
  {
    question: 'What is the payment structure?',
    answer:
      "If selected for writer benefit program, After signing the contract, the writer is then eligible to get one time signing bonus ;  daily publish bonus every month based on words published & missed publishing days in respective month. Apart from this the writer is also eligible for completion bonus if the writer completes the story post 250,000 published words given that the novel's engagement data is statisfactory. You can refer to respective sections in rewards & benefit section in writer benefit page",
  },
  {
    question: 'How many days do I need to write?',
    answer:
      'Writers need to upload chapters on a daily basis with quality content. A writer can miss publishing chapters up to a maximum of five days in a month, not more than that.',
  },
  {
    question: 'How many words need to publish a chapter',
    answer:
      'Each chapter must contain a minimum 1000 words or maximum 5000 words and the story must contain a minimum 30000 words to get eligible to apply for writer benefit and maximum as much as you want to write.',
  },
  {
    question: 'Why do I have to sign the contract?',
    answer:
      'Contracts include the terms and conditions which a writer is subject to if he/she wants to enter the Writers Benefit Program. So, if a writer wants to enjoy the benefits of WBP and be properly compensated for his or her work, he or she must sign the contract.',
  },
  {
    question: 'Can I write short stories and Poetry or Shayari?',
    answer:
      'We have observed that fiction stories have much higher readers than non-fiction, shayari, and poetry. So we recommend that all writers write original fiction stories. Hence, even if we allow writers to write short stories and poems, they are not eligible to apply for the Writers Benefit Program.',
  },
  {
    question: 'Can I write from outside India?',
    answer:
      'Our app is available globally. We welcome all writers from all countries.',
  },
  {
    question: 'Which languages can I write in?',
    answer:
      'You are welcome to write in any language option seen, but the Writer benefit program is currently only open for Hindi and Tamil.',
  },
  {
    question: 'What are coins?',
    answer:
      'Coins are used to support the writers financially. Except for the first few chapters of a novel, all the other chapters are locked for a limited time. Coins can be used to unlock these chapters and get early access to the entire story.',
  },
  {
    question: 'Why is my novel not visible on app',
    answer:
      'As we get hundereds of new books published daily, we are only able to feature the books with good performance in our featured list . To become featured in our app, make sure to write regularly with hooky long story & plotline',
  },
  {
    question:
      'What are the minimum performance guidelines for signing a novel?',
    answer:
      "Approximately only top 10% of the applications to the program are accepted, please make sure your readers are engaged with chapters added to your novel. You can refer & compare your novel's performances against Platform's Avg book data by going to Analytics Tab -> Engagement -> valid reader % vs Chapter Graph",
  },
  {
    question: 'Will my story be made into an audiobook?',
    answer:
      "If your book gets a high readership & enagagement , we'll convert it to an audiobook format. Your novel must have a word count of at least 2,50,000 words to be eligible to be made into an audiobook.",
  },
  {
    question: 'What is the mode of payment?',
    answer:
      'Your earnings will be reflected in your Pocket Novel account. The said amount might be subject to adjustment based on the defined criteria in some cases. The final eligible payout will be deposited in the writer’s associated bank account by 15th of every month.',
  },
  {
    question: 'Issues with email and password',
    answer:
      "Double-check that you have entered your username and password correctly. Remember, password is case-sensitive. If you're still having trouble, you can use the `Forgot your Username or Password?` link to have a password reset link emailed to you on your registered email ID. If the email doesn't appear in your inbox, check your spam/junk mail folder. If you're still having difficulties, email us the screenshot/video of the issue at <a href='mailto:writers@pocketfm.com'>writers@pocketfm.com</a>",
  },
  {
    question: 'What to do, if my App not working',
    answer:
      "Restart your phone and check for updates. If you are still facing the same issue, 'force stop' the Pocket Novel app from your phone's Settings and try clearing app cache. If the problem is not yet solved, email us at writers@pocketfm.com with a screenshot/video of the issue.",
  },
  {
    question: 'How do writers benefit from coins?',
    answer:
      'Performance Bonus will be shared with writers based on readers engagement with coins.Please watch this video for more detailed information: ',
    youtubeLink: 'i5knJeoyCnY',
  },
  {
    question: 'How to upload the Book Cover?',
    answer:
      'Go to the novels, choose the edit novel option. On the right side, there is an option to update the cover image. You can upload the book cover from there and also publish the novel from your side. Our graphic team will upload a book cover for you if the novel performs well.',
  },
];

const thankYouMessage = [
  'Your support is my inspiration!',
  'Thanks for all your support!',
  'You make a difference!',
  'You fuel my creativity!',
  'Grateful for your gifts!',
  "You're my literary companion!",
  'Thank you for motivating!',
  'You inspire my writing!',
  'Your kindness means everything!',
  "I'm grateful for your generosity!",
  'Thank you for empowering me!',
  'Your support fuels my passion!',
  'You make my writing journey brighter!',
  'Your gifts inspire my storytelling!',
  'Your encouragement is priceless!',
];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const novelChapterWordCount = 1000;

const novelThumbnailBackgroundColorArray = [
  '#34586E',
  '#DFB160',
  '#D18D96',
  '#89CFF0',
  '#00B1B0',
  '#A91B60',
  '#658EA9',
  '#A96036',
  '#D09064',
  '#808080',
  '#AA98A9',
  '#D6C41C',
  '#89CFF0',
  '#CBC3E3',
  '#483248',
  '#702963',
  '#800020',
  '#AA336A',
  '#5D3FD3',
  '#96DED1',
  '#89CFF0',
  '#5F9EA0',
  '#9F2B68',
  '#6B8E23',
  '#808000',
  '#3CB371',
  '#FF7F50',
  '#FFA500',
  '#FF8C00',
  '#C4C4C4',
];

const disabledMessageForWBPUS = (
  <>
    <h4>Hi!</h4>
    <p>
      We regret to inform you that we&apos;re indefinitely suspending our
      Novel&apos;s Writers Benefit Program outside of India. This has been a
      tough call for us, particularly given the amazing content that had started
      to come onto our platform and the reader reviews that we had received on
      some of the novels.{' '}
    </p>
    <p>
      We will pay all eligible writers, where we had signed a contract, their
      dues based on book performance and word counts as on 26th October, 2022
      and will be in touch for this shortly.
    </p>
    <p>
      We regret the sudden news and any disappointment it may have caused. We
      hope to resume this program at some point in the future in your country
      and we will be in touch when we do!
    </p>
    <p>Best regards,</p>
    <p>Pocket Novels</p>
  </>
);

const menuItemsArray = [
  {
    slug: 'home',
    label: 'Home',
    link: '/home',
  },
  {
    slug: 'novels',
    label: 'Novels',
    link: '/shows/novels',
  },
  {
    slug: 'my_creations',
    label: 'Creations',
    link: '/my-creations',
  },
  {
    slug: 'writers_benefit',
    label: 'Benefits',
    link: '/writers-benefit',
  },
  {
    slug: 'income',
    label: 'Income',
    link: '/income',
  },
  {
    slug: 'analytics',
    label: 'Analytics',
    link: '/analytics',
  },
  {
    slug: 'writers_academy',
    label: 'Academy',
    link: '/writers-academy',
    target: '_self',
  },
  {
    slug: 'writers_academy',
    label: 'Academy',
    link: 'https://www.youtube.com/watch?v=fBv3_bSne3Q&list=PL8qYx2UnWMbfr-fVe713sr19F9E42GcJG',
    target: '_blank',
  },
];

const menuItemObj = menuItemsArray.reduce((obj, item) => {
  if (item.submenu) {
    item.submenu.reduce((object, sub) => {
      obj[sub.slug] = sub.link;
    }, {});
  }
  obj[item.slug] = item.link;
  return obj;
}, {});

const defaultImageUrl = 'https://djhonz7dexnot.cloudfront.net/newneutral2.jpg';

const creationSubTabs = [
  { name: 'Novels', Icon: NovelsIcon },
  { name: 'Series', Icon: Headphone },
];

const sequencingWarning = {
  title: 'Audio Creation In Progress',
  message:
    'Your text is in the process of being converted to audio, which can take up to 2 hours. Kindly make chapter sequencing updates after this conversion is complete. Thank you for your patience!',
  confirmText: 'Okay',
};

export {
  creationSubTabs,
  defaultImageUrl,
  thankYouMessage,
  menuItemObj,
  menuItemsArray,
  audiobooks,
  commonFAQ,
  audioSeries,
  tags,
  novelsTopics,
  novelChapterWordCount,
  countryList,
  months,
  disabledMessageForWBPUS,
  novelThumbnailBackgroundColorArray,
  languages,
  sequencingWarning,
};
