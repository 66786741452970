const set = (key, data) => {
  if (key && data) {
    try {
      window.localStorage.setItem(
        key,
        typeof data === 'object' ? JSON.stringify(data) : data
      )
      return true
    } catch (e) {}
  }
  console.error('Parameter Error - Please check set method')
  return false
}

const get = (key) => {
  if (key) {
    return window.localStorage.getItem(key)
  }
  console.error('Parameter Error -  Please check get method.')
  return false
}

const unset = (key) => {
  if (key) {
    window.localStorage.removeItem(key)
    return true
  }
  console.error('Parameter Error -  Please check unset method.')
  return false
}

export { set, get, unset }
