/* eslint-disable */
import FuzzySet from 'fuzzyset';
import { convert } from 'html-to-text';
import queryString from 'query-string';
import { v4 } from 'uuid';

import { months, novelsTopics } from '../../constants';
import { allLanguages } from '../../pages/Home/constants';
import { get, post } from '../api';
import { get as getFromLocal, set, unset } from '../storage';
import { debounce } from './debounce';

const currentVersionTag = import.meta.env.VITE_APP_VERSION_TAG || '';

const browserTypesMapping = {
  Chrome: 'chrome',
  Firefox: 'firefox',
  Opera: 'opera',
  Safari: 'safari',
  Edge: 'edge',
  Chromium: 'chromium',
  Ie: 'ie',
  IE: 'ie',
  'Internet Explorer': 'ie',
  'Mobile Safari': 'safari',
  'MIUI Browser': 'mi',
  'Samsung Browser': 'samsung',
};

const osTypesMapping = {
  iOS: 'ios',
  Android: 'android',
  'Windows Phone': 'windowsphone',
  Windows: 'windows',
  'Mac OS': 'macos',
};

const deviceTypeMapping = {
  browser: 'desktop',
  Browser: 'desktop',
  phone: 'mobile',
  smartphone: 'mobile',
  tablet: 'tablet',
  Tablet: 'tablet',
  mobile: 'mobile',
};

const getDeviceDetails = () => {
  if (!window.UAParser) {
    return {};
  }
  const parser = new window.UAParser();
  const {
    browser: browserObject,
    os: osObject,
    device: deviceObject,
  } = parser.getResult();
  const browserName = browserObject.name;
  const osName = osObject.name;
  const deviceType = deviceObject.type;
  let deviceDetails = JSON.parse(window.localStorage.getItem('device_details'));

  if (!deviceDetails) {
    const type =
      deviceTypeMapping[deviceType] ||
      (deviceType || 'desktop').split(' ').shift().toLowerCase();
    const browser =
      browserTypesMapping[browserName] ||
      (browserName || 'NA').split(' ').shift().toLowerCase();
    const os =
      osTypesMapping[osName] ||
      (osName || 'NA').split(' ').shift().toLowerCase();
    deviceDetails = { type, browser, os };
    localStorage.setItem('device_details', JSON.stringify(deviceDetails));
  }
  return deviceDetails;
};

const makePlatformStringFromDeviceInfo = () => {
  let platformType = '';
  let platformOS = '';
  let platformBrowser = '';

  const { type, browser, os } = getDeviceDetails();

  const origin = localStorage.getItem('app_origin');
  if (origin) {
    if (origin.toLowerCase().includes('pfm_ios')) {
      platformOS = 'ios';
      platformBrowser = 'pfm';
    } else if (origin.toLowerCase().includes('pfm_android')) {
      platformOS = 'android';
      platformBrowser = 'pfm';
    } else if (origin.toLowerCase().includes('android')) {
      platformOS = 'android';
      platformBrowser = 'pn';
    } else if (origin.toLowerCase().includes('ios')) {
      platformOS = 'ios';
      platformBrowser = 'pn';
    }
  }

  platformType = platformType || type || 'na';
  platformOS = platformOS || os || 'na';
  platformBrowser = platformBrowser || browser || 'na';

  return `${platformType}-${platformOS}-${platformBrowser}`;
};

const getNameFromUrl = (url, title) => {
  if (!url) return '';
  const urlSplit = url.split('/');
  return urlSplit.length > 1
    ? title
      ? `${title}.${urlSplit.pop().split('.').pop()}`
      : urlSplit.pop()
    : '';
};

const getTokenAndUid = function () {
  const token = window.localStorage.getItem('token');
  const uid = window.localStorage.getItem('uid');
  return { token, uid };
};
const setAuthToken = function (key, value) {
  if (!(key && value)) return;
  window.localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value
  );
};
const isLoggedIn = function () {
  const { token, uid } = getTokenAndUid();
  return Boolean(token && uid);
};

const saveSession = function (sessionData) {
  for (const key in sessionData) {
    if (sessionData[key]) {
      window.localStorage.setItem(
        key,
        typeof sessionData[key] === 'object'
          ? JSON.stringify(sessionData[key])
          : sessionData[key]
      );
    }
  }
};
const clearSession = function () {
  // window.localStorage.clear()
  unset('uid');
  unset('name');
  unset('image_url');
  unset('token');
  unset('writer_notification_arr');
  unset('contestId');
  unset('contestBooks');
  unset('isSideDrawerVisible');
  set('isLoggedIn', 'false');
  // unset('bookSelected')
};

const getTopicNameFromId = (id) => {
  if (!id) return undefined;

  const allTopics = JSON.parse(getFromLocal('allTopics')) || [];
  const selectedTopic =
    allTopics
      .reduce((finalArr, { sub_topics }) => finalArr.concat(sub_topics), [])
      .find((topic) => topic.topic_id === id) || {};
  return selectedTopic.topic_name;
};
const getNovelTopicNameFromId = (id) => {
  if (!id) return undefined;

  const selectedTopic =
    novelsTopics
      .reduce((finalArr, { sub_topics }) => finalArr.concat(sub_topics), [])
      .find((topic) => topic.topic_id === id) || {};
  return selectedTopic.topic_name;
};

const numFormatter = (num, flag = 'K') => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000 && (flag === 'M' || flag === 'K')) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000 && flag === 'K') {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num;
};

const getTimeFromSeconds = (seconds) => {
  if (seconds !== 0 && !seconds) return seconds;
  return new Date(Number(seconds) * 1000)
    .toISOString()
    .substr(11, 8)
    .split(':')
    .map((el, i) => (i === 0 && el === '00' ? '' : el))
    .filter(Boolean)
    .join(':');
};

const objectCloneWithTopLevelNullRemoved = (object) => {
  if (!object) return;
  return Object.entries(object).reduce(
    (obj, [a, b]) => (b === null || b === undefined ? obj : { ...obj, [a]: b }),
    {}
  );
};

const parentTopicIds = [
  {
    topic_id: 'b71216c05fa0ac8f6f903af7d1600fcc69a297b8',
    language: 'hindi',
    name: 'Audiobook',
  },
  {
    topic_id: '234e590d77cdab7f54bc7411bf0b77e826facdf4',
    language: 'hindi',
    name: 'Audiobook Series',
  },
  {
    topic_id: '349176de48be18e8393aab288ce8564721c6f578',
    language: 'tamil',
    name: 'Audiobook',
  },
  {
    topic_id: 'fb318937f9e857038e16d9c5c90ef13b53dac484',
    language: 'tamil',
    name: 'Audiobook Series',
  },
  {
    topic_id: 'bf33ba42ff7cc10c56079fcf78f00016bee0152d',
    language: 'bengali',
    name: 'Audiobook',
  },
  {
    topic_id: '57b330dd40916bda4697e765a4b0510603cdbb3e',
    language: 'bengali',
    name: 'Audiobook Series',
  },
  { topic_id: 'malayalam-audiobook', language: 'malayalam', name: 'Audiobook' },
  {
    topic_id: 'a6fd6ec0fc2bc5835b51f09002576b5d4d0ebeed',
    language: 'malayalam',
    name: 'Audiobook Series',
  },
  { topic_id: 'telugu-audiobook', language: 'telugu', name: 'Audiobook' },
  {
    topic_id: '05006e8b81ddb504d6f7c2366d395c9681b0d562',
    language: 'telugu',
    name: 'Audiobook Series',
  },
  { topic_id: 'marathi-audiobook', language: 'marathi', name: 'Audiobook' },
  {
    topic_id: '09119c9393f4ea1ed0b76613d7d615e1a8b73eac',
    language: 'marathi',
    name: 'Audiobook Series',
  },
  { topic_id: 'kannada-audiobook', language: 'kannada', name: 'Audiobook' },
  {
    topic_id: 'e5330bdcc3e805badaf57e34797ee5720f8a5f51',
    language: 'kannada',
    name: 'Audiobook Series',
  },
  { topic_id: 'english-audiobook', language: 'english', name: 'Audiobook' },
  {
    topic_id: 'd79a4750949835a163280466f746a82f4e887a92',
    language: 'english',
    name: 'Audiobook Series',
  },
];

const novelsParentTopicIds = [
  {
    topic_id: '1c42d6fed3c2342d391be5d92bcd22bb16b84b73',
    language: 'hindi',
    name: 'Series',
  },
  { topic_id: 'any random key 123', language: 'hindi', name: 'Short Story' },
  {
    topic_id: '5fc60105f469b212c7d3f8f2b8bf0f91d38d00fd',
    language: 'english',
    name: 'Series',
  },
  { topic_id: 'english-ss-key', language: 'english', name: 'Short Story' },
];

// const clearSession = function () {
//   let cookie = new Cookies();
//   cookie.remove("uid", { path: '/' })
//   cookie.remove("token", { path: '/' })
//   cookie.remove("name", { path: '/' })
//   cookie.remove("image_url", { path: '/' })
// }

const ratingColorMapping = {
  '0.0': '#7e0100',
  0: '#7e0100',
  1: '#7e0100',
  '1.0': '#7e0100',
  1.1: '#880100',
  1.2: '#910100',
  1.3: '#9c0100',
  1.4: '#a70000',
  1.5: '#b10000',
  1.6: '#bc0000',
  1.7: '#c70001',
  1.8: '#d20001',
  1.9: '#dc0001',
  2: '#e70001',
  '2.0': '#e70001',
  2.1: '#eb0806',
  2.2: '#ef1b11',
  2.3: '#f32d1c',
  2.4: '#f64229',
  2.5: '#fa5534',
  2.6: '#fe6b41',
  2.7: '#fe773c',
  2.8: '#fc8234',
  2.9: '#fb8b2d',
  3: '#fa9626',
  '3.0': '#fa9626',
  3.1: '#f69922',
  3.2: '#eb9b1b',
  3.3: '#df9d14',
  3.4: '#d39f0e',
  3.5: '#c7a107',
  3.6: '#b8a509',
  3.7: '#a9aa0a',
  3.8: '#9aaf0c',
  3.9: '#8cb40e',
  4: '#7bb910',
  '4.0': '#7bb910',
  4.1: '#75b711',
  4.2: '#68ad14',
  4.3: '#5ca516',
  4.4: '#4f9b19',
  4.5: '#42911c',
  4.6: '#36881f',
  4.7: '#297e22',
  4.8: '#1c7524',
  4.9: '#106c27',
  5: '#0d6629',
  '5.0': '#0d6629',
};

const contractObj = {
  default: {
    ctaTitle: 'Confirm Contract details',
    heading: 'Congrats! You are eligible to sign a contract',
    subHeading:
      'Get signing bonus of INR 3000 when you complete 30,000 words & upto INR 6000 monthly',
    cautionNote: 'Make sure all details are accurate',
  },
  signContract: {
    ctaTitle: 'Sign Contract',
    heading: 'Congrats! You are eligible to sign a contract',
    subHeading:
      'Your application will be approved based on your novels quality & performance',
    cautionNote:
      'Please read through the document carefully & note that if the book is with copied content, it will auto terminate the contract',
  },
  inReview: {
    ctaTitle: ' In Review',
    heading: 'Thank you for signing',
    subHeading:
      'We will be reviewing the contract & content quality & Signing the contract from our end shortly',
    cautionNote: 'It might take 1-2 weeks post completion of 30k words.',
  },
  success: {
    ctaTitle: ' View Contract',
    heading: 'Congrats! Your contract is signed',
    subHeading:
      'You will start receiving payments after a final check on declared info.',
    cautionNote:
      'Payments for Rewards will be made till 15th of every month for last cycle',
  },
  confirmToSend: {
    ctaTitle: ' Confirm to send',
    heading: 'Please click on Confirm, to send Contract to Guardian',
    subHeading: 'Congrats! Your writer benefit application is approved.',
    cautionNote: 'We will inform you when the contract is sent',
  },
  undertakerApplied: {
    ctaTitle: 'Contract Sent for signing',
    heading: 'Your Contract has been sent to your guardian',
    subHeading: 'Please ask your guardian to e-sign the contract',
    cautionNote: 'It might take us upto 1 day to update the next status.',
  },
  undertakerSigned: {
    ctaTitle: 'Sign Contract',
    heading: 'Congrats! Your guardian has signed the contract.',
    subHeading: 'Please sign the contract to proceed.',
    cautionNote:
      'Please read through the document carefully & note that contract will be auto terminated if the book is not orginal fiction content',
  },
};

const contractObjVariantB = {
  default: {
    ctaTitle: 'Apply for WBP',
    heading: '🎉 Congratulations!',
    subHeading: 'You are eligible to apply for Writers Benefit program',
    cautionNote:
      '🎉 Congratulations! On completing 30k words. You’re now eligible to apply for the Writer’s Benefit Program.',
  },
  viewApplication: {
    ctaTitle: 'View your application',
    heading: '🎉 Congratulations!',
    subHeading:
      'You’ve successfully applied for the Writers Benefit program. Your application is under review.',
    cautionNote:
      ' Keep writing to attract more readers. Remember, only the top 10% are selected for the program. Evaluation may take up to one month or more. Once approved, you can monetise. Stay tuned for updates!',
  },
  monetize: {
    ctaTitle: 'Monetise now',
    heading: '🎉 Great news! ',
    subHeading: 'Your writing has captured the interest of readers',
    cautionNote:
      'You’re qualified for monetisation through the Writers Benefit Program. Simply provide the required details and sign the WBP contract to start earning from your work.',
  },
  signContract: {
    ctaTitle: 'Sign & Monetise',
    heading: '🎉 Congratulations!',
    subHeading: 'You are eligible to sign & monetise your novel.',
    cautionNote:
      'You are one step away from starting to earn from your novel. Sign and monetise now.',
  },
};

function gcd(u, v) {
  if (u === v) return u;
  if (u === 0) return v;
  if (v === 0) return u;

  if (~u & 1)
    if (v & 1) return gcd(u >> 1, v);
    else return gcd(u >> 1, v >> 1) << 1;

  if (~v & 1) return gcd(u, v >> 1);

  if (u > v) return gcd((u - v) >> 1, v);

  return gcd((v - u) >> 1, u);
}

/* returns an array with the ratio */
const getRatio = (w, h) => {
  const d = gcd(w, h);
  return [w / d, h / d];
};

const checkAspectRatioMatch = (w, h, ratioArr) => {
  if (!w && !h && !ratioArr) return false;
  const [width, height] = getRatio(w, h);
  if (width === ratioArr[0] && height === ratioArr[1]) return true;
  return false;
};

const removeHtmlTags = (str) => {
  if (str) {
    return str.replace(/(<([^>]+)>)/gi, '');
  }
  return str;
};

const convertUrlToHttps = (url, forceConvertAll = false) => {
  if (!url) return;
  if (forceConvertAll) {
    return url.replace('http', 'https').replace('httpss', 'https');
  }
  if (window.location.protocol === 'https:') {
    return url.replace('http', 'https').replace('httpss', 'https');
  }
  return url;
};
const monthsLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const getWordCountFromText = (text) => {
  if (!text) return 0;
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = text.replace(regex, ' ').trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
};

const convertToQueryString = (obj, key) => {
  const QString = queryString.stringify({
    [key]: JSON.stringify(obj),
  });
  return QString;
};

const handleEventLog = (screenName, event, metaDataObj = {}) => {
  let { uid } = getTokenAndUid();
  const metaData = { ...metaDataObj };
  if (metaData.uid) {
    uid = metaData.uid;
  }
  const id = v4();
  const eventId = id.split('-').join('');
  let source =
    localStorage.getItem('referrer') !== 'undefined'
      ? localStorage.getItem('referrer')
      : '';
  const medium = sessionStorage.getItem('medium');
  const campaign = sessionStorage.getItem('campaign');
  source = `${source}_medium_${medium || ''}_campaign_${campaign || ''}`;
  const data = {
    screen_name: screenName,
    client_ts: String(Date.now()),
    event,
    view_type: metaData.route,
    source: `${source}_${makePlatformStringFromDeviceInfo()}`,
    resolution: `${window.innerWidth}*${window.innerHeight}`,
    app_version_code: currentVersionTag,
    type: metaDataObj.type,
  };
  if (!data.type) delete data.type;
  if (!localStorage.getItem('uuid')) {
    const uniqueId = v4().split('-').join('');
    localStorage.setItem('uuid', uniqueId);
  }
  const common_fields = {
    device_id: localStorage.getItem('uuid'),
    uid,
  };
  if (metaData.phone_number) {
    data.phone = metaData.phone_number;
  }
  if (metaData.button) {
    data.module_name = metaData.button;
  }
  if (metaData.module_name) {
    data.module_name = metaData.module_name;
  }
  if (metaData.time) {
    data.time_spent = String(metaData.time);
  }
  if (metaData.entity_type) {
    data.entity_type = metaData.entity_type;
    data.entity_id = metaData.entity_id;
  }
  if (metaData.time_since_load_start) {
    data.time_since_load_start = metaData.time_since_load_start;
  }
  if (metaData.show_id) {
    data.show_id = metaData.show_id;
  }
  if (metaData.view_id) {
    data.view_id = metaData.view_id;
  }
  const events = [{ data, eventId }];
  const postData = {
    events,
    common_fields,
    group: 'user_events',
  };

  const platform = 'writers';

  if (import.meta.env.VITE_APP_DEPLOY_ENV !== 'production') {
    console.log({ eventData: postData });
    return;
  }

  post('https://api.pocketfm.in/v2/logging_data/log', {
    data: postData,
    headers: { platform },
  })
    .then(() => {})
    .catch((err) => {
      console.log('logging  error', err);
    });
};

const handleFacebookEvents = (custom, eventName = '', metaData) => {
  if (import.meta.env.VITE_APP_DEPLOY_ENV !== 'production') {
    console.log('pixel event', custom);
    return;
  }
  if (typeof window.fbq !== 'undefined') {
    if (metaData) {
      window.fbq(
        custom ? 'trackCustom' : 'track',
        custom ? eventName : 'CompleteRegistration',
        metaData
      );
    } else {
      window.fbq(
        custom ? 'trackCustom' : 'track',
        custom ? eventName : 'CompleteRegistration'
      );
    }
  }
};

const calculateLoadTimeDifference = (currentTime) => {
  const appLoadStartTime = +getFromLocal('app_load_start_time');
  if (appLoadStartTime > 0) return (currentTime - appLoadStartTime) / 1000;
};

const handlePageLoadEventLog = (screenName, metaObj = {}) => {
  const timeSinceLoadStart = calculateLoadTimeDifference(Date.now());
  const eventLogMetaObj = {
    ...metaObj,
    route: window.location.pathname + window.location.search,
  };
  if (timeSinceLoadStart)
    eventLogMetaObj.time_since_load_start = timeSinceLoadStart.toString();
  handleEventLog(screenName, 'page_load', eventLogMetaObj);
  unset('app_load_start_time');
};

export const handleVideoWatchedEventLog = (
  screenName,
  progress,
  metaObj = {}
) => {
  const eventLogMetaObj = {
    ...metaObj,
    route: window.location.pathname + window.location.search,
  };
  handleEventLog(screenName, `video_watched_${progress}`, eventLogMetaObj);
};

export const handleVideoPlayedEventLog = (screenName, metaObj = {}) => {
  const eventLogMetaObj = {
    ...metaObj,
    route: window.location.pathname + window.location.search,
  };
  handleEventLog(screenName, `video_played`, eventLogMetaObj);
};

const calculateStringMatchScore = (authorName, accountName) => {
  const fuzzy = FuzzySet([accountName]);
  const stringMatchScore = fuzzy.get(authorName || '');
  return stringMatchScore ? stringMatchScore[0][0] >= 0.5 : false;
};

const getLanguages = (countryCode = 'IN') => {
  const otherCountryCodes = [
    'BD',
    'PK',
    'SG',
    'NP',
    'AE',
    'QA',
    'OM',
    'LK',
    'BH',
  ];
  if (otherCountryCodes.includes(countryCode)) {
    return [
      { id: 1, name: 'tamil' },
      { id: 2, name: 'bengali' },
      { id: 3, name: 'english' },
      { id: 4, name: 'hindi' },
    ];
  }
  if (countryCode !== 'IN') {
    return allLanguages.filter((lang) => lang.name === 'english');
  }
  return allLanguages;
};

const extractDocuments = (documents) => {
  let cancelCheque = {};
  let govtId = {};
  let w9 = {};
  let ssn_or_ein = {};
  let w8 = {};
  let tax_id = {};
  let pan_card = {};
  for (const doc in documents) {
    if (documents[doc]?.cancelled_cheque_url) {
      cancelCheque = documents[doc]?.cancelled_cheque_url;
    } else if (documents[doc]?.govt_id_url) {
      govtId = documents[doc]?.govt_id_url;
    } else if (documents[doc]?.w9) {
      w9 = documents[doc]?.w9;
    } else if (documents[doc]?.ssn_or_ein_url) {
      ssn_or_ein = documents[doc]?.ssn_or_ein_url;
    } else if (documents[doc]?.w8) {
      w8 = documents[doc]?.w8;
    } else if (documents[doc]?.tax_id_url) {
      tax_id = documents[doc]?.tax_id_url;
    } else if (documents[doc]?.pan_card_url) {
      pan_card = documents[doc]?.pan_card_url;
    }
  }
  return [cancelCheque, govtId, w9, ssn_or_ein, w8, tax_id, pan_card];
};

const getParticularDocument = (documents, doc_name, type) => {
  const index = documents.findIndex((doc) => doc[doc_name]);
  return index >= 0 ? documents[index][doc_name][type] : '';
};

const getDocObj = (doc_name, doc_url, documents) => {
  const id = getParticularDocument(documents, doc_name, 'id');
  return id === '' ? { url: doc_url } : { url: doc_url, id };
};

const checkOverseasUser = (user) => {
  // return false when user === indian else return true
  const isPhoneCodeIndian =
    user?.phone_number && (user?.phone_number).slice(0, 3) === '+91';
  // considering every english author as ROW user and showing UGC flow
  if (user?.languages === 'english') return true;
  if (!isPhoneCodeIndian) {
    if (user?.location === 'IN') return false;
    if (user?.location === '' && user?.login_type === 'email_login')
      return true;
    if (user?.location) return true;
  }
  return false;
};

const getLocale = (user) => {
  return user?.location === 'US'
    ? 'US'
    : user?.location === 'IN'
      ? 'IN'
      : 'ROW';
};

const redirectDeeplink = (navigate, redirectTo, defaultPage = '/login') => {
  if (redirectTo) {
    const sanitizedRedirectTo = redirectTo.replace(/^\/+/, '');
    navigate(`/${sanitizedRedirectTo}`);
  } else {
    navigate(defaultPage);
  }
};

const dateFormatter = (dateToBeFormated) => {
  const date = new Date(dateToBeFormated);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];
  return `${year} ${monthNames[month]} ${day}`;
};

const isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

/*
st is used with numbers ending in 1 (e.g. 1st, pronounced first)
nd is used with numbers ending in 2 (e.g. 92nd, pronounced ninety-second)
rd is used with numbers ending in 3 (e.g. 33rd, pronounced thirty-third)
As an exception to the above rules, all the "teen" numbers ending with 11, 12 or 13 use -th (e.g. 11th, pronounced eleventh, 112th, pronounced one hundred [and] twelfth)
th is used for all other numbers (e.g. 9th, pronounced ninth).
*/

function suffixToNumber(num) {
  var j = num % 10,
    k = num % 100;
  if (j == 1 && k != 11) {
    return num + 'st';
  }
  if (j == 2 && k != 12) {
    return num + 'nd';
  }
  if (j == 3 && k != 13) {
    return num + 'rd';
  }
  return num + 'th';
}
function formatDate(inputDate, withoutDate) {
  if (!inputDate) return '';
  // Parse the input date string into a Date object
  const dateParts = inputDate.split('/');
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10) + 2000; // Assuming '23' refers to the year 2023

  // Convert the month to its 3-letter abbreviation
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthAbbreviation = monthNames[month - 1];

  if (withoutDate) {
    return `${monthAbbreviation} '${year.toString().slice(-2)}`;
  }
  const outputDate = `${suffixToNumber(day)} ${monthAbbreviation} '${year.toString().slice(-2)}`;
  return outputDate;
}
const redirectToNovelLinkHandler = (id) => {
  const origin = localStorage.getItem('app_origin') || '';
  const nativeAppVersion = localStorage.getItem('native_app_version') || '';
  const isPocketNovelAndroid = origin.toLowerCase().includes('android');
  if (!isMobileOrTablet()) {
    window.open(`https://www.pocketnovel.com/novel/${id}`);
  } else if (origin.toLowerCase().includes('pfm')) {
    window.location =
      !nativeAppVersion || nativeAppVersion <= 666
        ? `https://www.pocketnovel.com/novel/${id}`
        : `https://pocketfm.onelink.me/2IE7?is_retargeting=true&deep_link_value=${encodeURIComponent('pocketfm://open?entity_type=book&entity_id=' + id)}&af_dp=${encodeURIComponent('pocketfm://open?entity_type=book&entity_id=' + id)}`;
  } else if (!isPocketNovelAndroid || nativeAppVersion) {
    window.location = `https://pocketnovel.onelink.me/tSZo?is_retargeting=true&deep_link_value=${encodeURIComponent('pocketnovel://open?entity_type=book&entity_id=' + id)}&af_dp=${encodeURIComponent('pocketnovel://open?entity_type=book&entity_id=' + id)}`;
  } else {
    window.open(`https://www.pocketnovel.com/novel/${id}`);
  }
};

const handleNotionOrExternalLink = (link, text, noStyle) => {
  if (window.Android && window.Android.handleExternalLink) {
    window.Android.handleExternalLink(link);
  } else if (window.webkit) {
    if (
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.handleExternalLink
    ) {
      console.log('handleExternalLink webkit.messageHandlers');
      window.webkit.messageHandlers.handleExternalLink.postMessage({
        link: link,
      });
    }
  } else {
    window.open(link, '_blank');
  }
};

const redirectToShowLinkHandler = (id) => {
  // if(!isMobileOrTablet()) {
  //   window.open(`https://www.pocketfm.com/show/${id}`)
  // }else if(window.Android) {
  //   if (window.Android.onCtaClicked) {
  //     console.log('Executed onCtaClicked')
  //     window.Android.onCtaClicked(`pocketfm://open?entity_type=show&entity_id=${id}`)
  //   }
  // }else if(window.webkit){
  //   if (window.webkit.messageHandlers) {
  //     console.log('inside webkit.onCtaClicked')
  //     window.webkit.messageHandlers.onCtaClicked.postMessage({ deeplink : `pocketfm://open?entity_type=show&entity_id=${id}` })
  //   }
  // }else {
  handleNotionOrExternalLink(`https://www.pocketfm.com/show/${id}`);
  // }
};

const wordCount = (value) => {
  if (!value) return 0;
  const result = [];
  const str = value.replace(/[\t\n\r\.\?\!]/gm, ' ').split(' ');
  str.forEach((s) => {
    const trimStr = s.trim();
    if (trimStr.length > 0) result.push(trimStr);
  });
  return result.length;
};

const emailContentGenerator = (user, bookId, subject, bottomLine) =>
  `mailto:writers@pocketfm.com?subject=${subject}&body=Phone%3A%20${user?.phone_number}%0D%0AEmail%3A%20${user?.email ? user?.email : ''}%0D%0ALocale%20%3A%20${user?.location ? user?.location : ''}%0D%0APlatform%3A%20${navigator.platform}%0D%0AUID%20%3A%20${user?.uid}%0D%0ABook%20id%3A%20${bookId}%0D%0AAuthor%20Name%3A%20${user?.fullname ? user?.fullname : ''}%0D%0A%0D%0A*************************%0D%0A${bottomLine}%0D%0A*************************`;

const removeSpecialCharacters = (str) =>
  str
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '')
    .replace(/\s\s+/g, ' ');

const getCleanContent = (content) => {
  const text = convert(content, {
    selectors: [{ selector: 'a', options: { ignoreHref: true } }],
  });
  return text;
};

const getWordCountFromURL = async (url) => {
  const urlContent = await get(url);
  const cleanText = removeSpecialCharacters(getCleanContent(urlContent));
  const fileWordCount = getWordCountFromText(cleanText);
  const repeatedWordCount = getRepeatedWordCount(cleanText);
  return { fileWordCount, repeatedWordCount };
};

const getPublishedDaysForSpecificMonth = (allPublishedDates, month, year) => {
  const publishedDates = [];
  for (let d = 0; d < allPublishedDates.length; d += 1) {
    const date = new Date(allPublishedDates[d].publish_time);
    if (
      months[date.getUTCMonth()] === month.substr(0, 3) &&
      Number(year) === date.getFullYear()
    ) {
      publishedDates.push(date.getUTCDate());
    }
  }
  return publishedDates.sort((a, b) => a - b);
};

const getDateClassNameForSpecificDate = (
  date,
  isNextMonthDatePresent,
  publishedDates
) => {
  let className = isNextMonthDatePresent ? 'missed' : '';
  const lastPublishedDate = publishedDates[publishedDates.length - 1];
  if (publishedDates.includes(date)) {
    className = 'published';
  } else if (!publishedDates.includes(date) && date < lastPublishedDate) {
    className = 'missed';
  }
  return className;
};

const checkNextMonthPublishDatePresent = (
  publishedDates,
  currentMonth,
  currentYear
) => {
  const currentMonthIndex = months.findIndex(
    (month) => month === currentMonth.substr(0, 3)
  );
  for (let d = 0; d < publishedDates.length; d += 1) {
    const publishMonthIndex = new Date(
      publishedDates[d].publish_time
    ).getMonth();
    if (
      currentMonthIndex < publishMonthIndex &&
      new Date(publishedDates[d].publish_time).getFullYear() ===
        Number(currentYear)
    )
      return true;
    if (
      currentMonthIndex > publishMonthIndex &&
      new Date(publishedDates[d].publish_time).getFullYear() >
        Number(currentYear)
    )
      return true;
  }
  return false;
};

const capitalizeString = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

const httpToHttps = (url) => {
  if (!url.includes('https://')) {
    return url.replace(/^http:\/\//i, 'https://');
  }
  return url;
};

const emailValidation = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const checkForGlobalLogin = () => {
  //regular expressions to extract IP and country values
  const countryCodeExpression = /loc=([\w]{2})/;
  const userIPExpression = /ip=([\w\.]+)/;

  //automatic country determination.
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.timeout = 3000;
    xhr.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 200) {
          const countryCode = countryCodeExpression.exec(this.responseText);
          const ip = userIPExpression.exec(this.responseText);
          if (
            countryCode === null ||
            countryCode[1] === '' ||
            ip === null ||
            ip[1] === ''
          ) {
            reject('IP/Country code detection failed');
          }
          const result = {
            countryCode: countryCode[1],
            IP: ip[1],
          };
          resolve(result);
        } else {
          reject(xhr.status);
        }
      }
    };
    xhr.ontimeout = function () {
      reject('timeout');
    };
    xhr.open('GET', 'https://www.cloudflare.com/cdn-cgi/trace', true);
    xhr.send();
  });
};

const isMobileOrTabletUser = () =>
  /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);

const waitForSomeTime = async (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('resolved');
    }, time);
  });
};

const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\+?\d{1,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
};

const getWordCountFromBoostExp = (isBoostIntialChap, chapterNumber) => {
  if (isBoostIntialChap) {
    return chapterNumber === 1
      ? 250
      : chapterNumber === 2
        ? 500
        : chapterNumber === 3
          ? 750
          : 1000;
  }
  return 1000;
};

const extractChapterSequenceUsingSplit = (title) => {
  const regex = /Ch\s*(\d+)/i;

  const parts = title.split(regex);

  if (parts.length >= 2 && !isNaN(parts[1])) {
    return parseInt(parts[1], 10);
  } else {
    return 5;
  }
};

const changeUrlWithoutReload = (
  prevState = null, // object
  url // string
) => {
  window.history.replaceState(prevState, '', url);
};

const booleanOptions = [
  { name: true, label: 'Yes' },
  { name: false, label: 'No' },
];

const contractTypeOptions = [
  { name: true, label: 'Exclusive' },
  { name: false, label: 'Non-Exclusive' },
];

const handleFlutterCallbacks = ({ method, data, elseDoThis }) => {
  const dataToSend = { method, data };
  if (window.FlutterCallback) {
    if (window.FlutterCallback.postMessage) {
      console.log(`FlutterCallback executed with method: ${method}`);
      window.FlutterCallback.postMessage(JSON.stringify(dataToSend));
    } else {
      console.log(
        'FlutterCallback is present but postMessage is not available'
      );
      elseDoThis();
    }
  } else {
    console.log('FlutterCallback is not available');
    elseDoThis();
  }
};

const removeEmojiFromText = (text) => {
  const emojiRegex =
    /(?:[\uD83C-\uDBFF\uDC00-\uDFFF][\uDC00-\uDFFF]|[\u200D-\uFE0F]|\uD83E[\uDD10-\uDDFF])/g;
  const backslashRegex = /\\/g;
  return text.replace(emojiRegex, '').replace(backslashRegex, '');
};

const getRepeatedWordCount = (content) => {
  if (content.length < 1) return 0;
  const words = content.split(' ');
  const wordMap = {};
  let totalRepeatedWords = 0;
  for (let i = 0; i < words.length; i += 1) {
    if (wordMap[words[i]]) {
      wordMap[words[i]] = wordMap[words[i]] + 1;
      totalRepeatedWords += 1;
    } else wordMap[words[i]] = 1;
  }
  return totalRepeatedWords;
};

export {
  extractChapterSequenceUsingSplit,
  getWordCountFromBoostExp,
  wordCount,
  debounce,
  getParticularDocument,
  getDocObj,
  contractObj,
  checkOverseasUser,
  extractDocuments,
  getLanguages,
  calculateStringMatchScore,
  handleEventLog,
  handleFacebookEvents,
  getTokenAndUid,
  setAuthToken,
  isLoggedIn,
  saveSession,
  clearSession,
  getNameFromUrl,
  getTopicNameFromId,
  getNovelTopicNameFromId,
  numFormatter,
  getTimeFromSeconds,
  objectCloneWithTopLevelNullRemoved,
  parentTopicIds,
  novelsParentTopicIds,
  ratingColorMapping,
  checkAspectRatioMatch,
  removeHtmlTags,
  convertUrlToHttps,
  monthsLong,
  monthsShort,
  getWordCountFromText,
  convertToQueryString,
  calculateLoadTimeDifference,
  handlePageLoadEventLog,
  makePlatformStringFromDeviceInfo,
  redirectDeeplink,
  dateFormatter,
  redirectToNovelLinkHandler,
  removeSpecialCharacters,
  emailContentGenerator,
  isMobileOrTablet,
  capitalizeString,
  getPublishedDaysForSpecificMonth,
  getDateClassNameForSpecificDate,
  checkNextMonthPublishDatePresent,
  getDeviceDetails,
  httpToHttps,
  suffixToNumber,
  formatDate,
  checkForGlobalLogin,
  isMobileOrTabletUser,
  emailValidation,
  redirectToShowLinkHandler,
  handleNotionOrExternalLink,
  waitForSomeTime,
  isValidPhoneNumber,
  changeUrlWithoutReload,
  contractObjVariantB,
  booleanOptions,
  contractTypeOptions,
  getLocale,
  handleFlutterCallbacks,
  removeEmojiFromText,
  getRepeatedWordCount,
  getCleanContent,
  getWordCountFromURL,
};
