import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import './style.css';

// TODO: convert this to styled compopnent

const Container = styled.div`
  display: ${({ inline }) => (inline ? 'inline-block' : 'flex')};
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: ${({ noPadding, inline }) => (noPadding || inline ? '0' : '14px')};
`;
const Spinner = styled.svg`
  margin: 0 auto;
  display: block;
  fill: transparent;
  stroke: ${({ loaderColor }) => loaderColor};
  strokewidth: ${({ loaderSize }) => (loaderSize === 'lg' ? '3' : '2')};
  animation: ${({ loaderSize }) =>
    loaderSize === 'sm'
      ? 'dash-sm 2s ease infinite,rotate 2s linear infinite'
      : loaderSize === 'md'
        ? 'dash-md 2s ease infinite,rotate 2s linear infinite'
        : loaderSize === 'lg'
          ? 'dash-lg 2s ease infinite,rotate 2s linear infinite'
          : 'dash-xs 2s ease infinite,rotate 2s linear infinite'};
`;

const Loader = function ({
  theme,
  size,
  invertColor,
  fjordColor,
  noPadding,
  inline,
  color,
}) {
  const loaderColor = fjordColor
    ? '#35394b'
    : invertColor
      ? '#fdfdfd'
      : color
        ? theme.colors[color.split('.')[0]][color.split('.')[1]]
        : theme.colors.interactive.azure2;
  const radiusMapping = {
    xs: 8,
    sm: 10,
    md: 15,
    lg: 25,
  };
  const loaderSize = size && size in radiusMapping ? size : 'lg';
  const r = radiusMapping[loaderSize];
  return (
    <Container inline={inline} noPadding={noPadding}>
      <Spinner
        loaderSize={loaderSize}
        loaderColor={loaderColor}
        width={2 * r}
        height={2 * r}
        version="1.1"
      >
        <circle cx={r} cy={r} r={r - 3} />
      </Spinner>
    </Container>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
};

export default withTheme(Loader);
