import React from 'react'

const WalletIcon = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M4.85781 9.90509H20.1177L19.0025 5.20015C18.9079 4.80185 18.5096 4.55291 18.1063 4.64751L5.10675 7.73435C4.70844 7.82894 4.45951 8.22724 4.5541 8.63052L4.85781 9.90509Z" fill="currentColor" />
        <path d="M17.8225 15.4017C17.3545 15.4017 16.9711 15.78 16.9711 16.253V18.379C16.9711 18.847 17.3495 19.2303 17.8225 19.2303H22.8461V15.4017H17.8225V15.4017ZM18.6988 18.3292C18.0814 18.3292 17.5786 17.8263 17.5786 17.2089C17.5786 16.5916 18.0814 16.0887 18.6988 16.0887C19.3161 16.0887 19.819 16.5916 19.819 17.2089C19.819 17.8263 19.3211 18.3292 18.6988 18.3292Z" fill="currentColor" />
        <path d="M18.1312 0.465349L18.0814 0.38071C17.8823 0.0222384 17.4292 -0.10721 17.0707 0.0969199L5.40549 6.61911C5.23123 6.7137 5.11672 6.86805 5.06195 7.0423L7.33227 6.5046L18.1312 0.465349Z" fill="currentColor" />
        <path d="M18.2756 3.94551C18.9378 3.94551 19.5103 4.39858 19.6647 5.04084L20.526 8.65542L21.5467 8.08784C21.9051 7.88869 22.0346 7.43562 21.8304 7.07715L19.1419 2.26766L14.6959 4.75705L17.947 3.98534C18.0565 3.95546 18.1661 3.94551 18.2756 3.94551Z" fill="currentColor" />
        <path d="M22.413 9.70594C22.1242 9.40224 21.7159 9.21304 21.2629 9.21304H20.7003L20.8198 9.70594H22.413Z" fill="currentColor" />
        <path d="M17.8225 14.7196H22.8461V10.7963C22.8461 10.6768 22.8312 10.5623 22.8063 10.4528H3.74257C3.23473 10.4528 2.8215 10.2885 2.8215 10.0793C2.8215 9.87522 3.23473 9.70594 3.74257 9.70594H4.15083L4.03134 9.21304H3.57827C2.70699 9.21304 2 9.92003 2 10.7913V22.4167C2 23.293 2.70699 24 3.58325 24H21.2629C22.1391 24 22.8461 23.293 22.8461 22.4167V19.9124H17.8225C16.9761 19.9124 16.2891 19.2253 16.2891 18.379V16.253C16.2891 15.4066 16.9761 14.7196 17.8225 14.7196Z" fill="currentColor" />
    </svg>

)

export default WalletIcon
