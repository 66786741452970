import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { v4 } from 'uuid';

import { theme } from './designSystem';
import AccountProvider from './providers/accountProvider';
import NotificationsProvider from './providers/notificationsProvider';
import GlobalRoutes from './routes';

import './App.css';

import AlertSnackBarWrapper from 'components/AlertSnackBarWrapper';

import { GlobalStyles } from './GlobalStyles';
import { getLocationInfo } from './lib/utils/events';

const handleNativePageLoad = (initial_page, showPopup = true) => {
  if (window.Android) {
    if (window.Android.onPageLoaded) {
      console.log('Executed onPageLoaded');
      window.Android.onPageLoaded(showPopup, 'onboarding');
    }
  } else {
    console.log('Function onPageLoaded not exist');
  }
  if (window.webkit) {
    if (
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.onPageLoaded
    ) {
      console.log('onPageLoaded webkit.messageHandlers');
      window.webkit.messageHandlers.onPageLoaded.postMessage({
        exit_confirmation: showPopup,
        initial_page_host: 'onboarding',
      });
    }
  }
};

const App = () => {
  const location = useLocation();

  useEffect(() => {
    const handleHistoryChange = () => {
      const { pathname } = location;
      if (
        pathname.includes('edit-profile') ||
        pathname.includes('edit-novel-details') ||
        pathname.includes('add-chapter') ||
        pathname.includes('edit-chapter')
      ) {
        handleNativePageLoad(true);
      } else handleNativePageLoad(false);
    };

    const handleWindowLocation = () => {
      if (window.location === window.parent.location) {
        const { referrer, origin, app_version, medium, campaign, entity_id } =
          queryString.parse(
            window.parent
              ? window.parent.location.search
              : window.location.search
          );

        if (entity_id) localStorage.setItem('entity_id', entity_id);
        if (medium) sessionStorage.setItem('medium', medium);
        if (campaign) sessionStorage.setItem('campaign', campaign);

        if (!referrer) {
          if (document.referrer.includes('google')) {
            localStorage.setItem('referrer', 'google');
          } else localStorage.setItem('referrer', 'url');
        } else {
          localStorage.setItem('referrer', referrer);
        }

        if (!localStorage.getItem('uuid')) {
          const id = v4().split('-').join('');
          localStorage.setItem('uuid', id);
        }

        if (origin) localStorage.setItem('app_origin', origin);
        if (app_version)
          localStorage.setItem('native_app_version', app_version);
      }
    };

    handleWindowLocation();
    handleHistoryChange();
    localStorage.removeItem('writer_notifications');
  }, [location]);

  return (
    <ThemeProvider theme={theme.light}>
      <GlobalStyles />
      <AccountProvider>
        <NotificationsProvider>
          <AlertSnackBarWrapper />
          <GlobalRoutes />
        </NotificationsProvider>
      </AccountProvider>
    </ThemeProvider>
  );
};

export default App;
