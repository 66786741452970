import React from 'react';
import styled from 'styled-components';
import NavigationBarAndDrawer from '../NavigationBarAndDrawer';
import SubHeader from '../WithSubHeaderBG';

const BlankPaper = styled.div`
  background-color: var(--Dove);
  height: 100vh;
  width: 100%;
  padding: 30px;
`;
export const ErrorComponent = () => (
  <BlankPaper>
    Something went wrong on our end. Please try again later
  </BlankPaper>
);

const ErrorPage = () => {
  return (
    <NavigationBarAndDrawer>
      <SubHeader>
        <ErrorComponent />
      </SubHeader>
    </NavigationBarAndDrawer>
  );
};

export default ErrorPage;
